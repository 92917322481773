@use "../_variables/variables" as *;
@use "effects" as *;
@use "others" as *;
@use "text" as *;

@mixin btn_base {
	@include f-w(500);
	display: block;
	text-decoration: none;
	position: relative;
	line-height: 1.4;
	@include transition;
	@include dec-none;
}

@mixin btn_normal(
	$color: $white,
	$bg: $main_c,
	$border: $main_c,
	$color_h: $white,
	$bg_h: $hover_c,
	$border_h: $hover_c
) {
	@include btn_base;
	color: $color;
	background-color: $bg;
	border: 1px solid;
	border-color: $border;
	padding: 1rem 4%;
	width: 100%;
	&:hover {
		background-color: $color_h;
		background-color: $bg_h;
		border-color: $border_h;
	}
}

//矢印あり
@mixin btn_arrow(
	$color: $white,
	$bg: $main_c,
	$border: $main_c,
	$color_h: $white,
	$bg_h: $hover_c,
	$border_h: $hover_c
) {
	@include btn_base;
	color: $color;
	background-color: $bg;
	border: 1px solid;
	border-color: $border;
	padding: 1.5em 5em 1.5em 1.75em;
	width: 100%;
	@include radius(999px);
	span {
		&::before {
			content: "";
			width: 8px;
			height: 8px;
			background: $white;
			position: absolute;
			top: 50%;
			@include radius(50%);
			right: 1.75em;
			transform: translateY(-50%);
			@include transition;
		}
		&::after {
			content: "";
			width: 32px;
			height: 1px;
			background: $white;
			position: absolute;
			top: 50%;
			left: calc(100% - 1.75em - 40px);
			@include transition;
		}
	}

	&:hover {
		background-color: $color_h;
		background-color: $bg_h;
		border-color: $border_h;
		span {
			&::before {
				right: calc(1.75em - 5px);
			}
			&::after {
				width: 37px;
			}
		}
	}
}
