@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	pumpcarlist
/--------------------------------------------------------------------*/

// pumpcarlist-item
.pumpcarlist-item {
	padding-bottom: clamp(6rem, 12vw, 12rem);
	&__list {
		@include flex-column;
		row-gap: clamp(4rem, 14vw, 14rem);
	}
}

.pumpcarlist-item__box {
	position: relative;
	padding-bottom: clamp(3rem, 4vw, 4rem);
	@include media-breakpoint-up(xl) {
		padding-bottom: clamp(3rem, 8vw, 8rem);
	}
	&::before {
		position: absolute;
		bottom: 0;
		right: 0;
		content: "";
		background: $bg_c1;
		width: 98%;
		height: 70%;
		border-top-left-radius: clamp(8px, 1.06vw, 16px);
		border-bottom-left-radius: clamp(8px, 1.06vw, 16px);
		@include media-breakpoint-up(xl) {
			max-width: calc(50% + 830px);
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
		@include media-breakpoint-up(md) {
			display: grid;
			grid-template-columns: 54% 40%;
			grid-template-rows: auto 1fr;
			column-gap: 6%;
		}
		@include media-breakpoint-up(lg) {
			grid-template-columns: 48% 40%;
			column-gap: 12%;
		}
	}
	&__tit {
		@include f-w(700);
		margin-bottom: calc(0.5rem + 0.75em);
		@include f-s_xxs(0.94, 14);
		position: relative;
		padding-top: 2em;
		@include media-breakpoint-up(md) {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
			@include f-s_md(1.14, 14);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 100vw;
			height: 1px;
			background: $main_c;
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			grid-column: 2 / 3;
			grid-row: 1 / 3;
		}
		&__inner {
			@include aspect-img;
			@include radius_m;
			overflow: hidden;
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
			max-width: 95%;
		}
	}
}

// pumpcarlist-pumpcar
.pumpcarlist-pumpcar {
	.txt {
		@include f-w(500);
	}
	.pagination {
		padding-top: clamp(3rem, 5vw, 5rem);
	}
	&__list {
		display: flex;
		margin-top: clamp(2rem, 3vw, 3rem);
		@include media-breakpoint-up(md) {
			column-gap: 2%;
			row-gap: clamp(2rem, 3vw, 3rem);
			flex-wrap: wrap;
			& > * {
				width: 49%;
			}
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
}

.pumpcar-box {
	padding-top: clamp(1.5rem,2vw,2rem);
	@include media-breakpoint-up(md) {
		padding-top: clamp(2rem,3vw,3rem);
	}
	&__inner {
		background: $white;
		@include radius_m;
		border: 1px solid $other_c3;
		padding: 1.5em 5% 2em;
		position: relative;
		@include media-breakpoint-up(md) {
			padding: 2em 3% 2em;
		}
	}
	&__add {
		position: absolute;
		left: 5%;
		@include line-h(1);
		bottom: calc(100% - .75em);
		color: $main_c;
		z-index: z-index(module, part03);
		@include f-s_xxs(1.25,12);
		background: $white;
		display: inline-block;
		padding: .2em .5em;
		@include media-breakpoint-up(md) {
			@include f-s_md(1.25,12);
			left: 3%;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
		b {
			@include f-w(700);
			font-size: 1.6em;
			@include f-family(font02);
		}
		span {
			@include f-w(700);
			@include f-family(font01);
		}
	}
	&__tit {
		margin-bottom: calc(1rem + 0.5em);
		@include f-w(700);
		text-align: center;
		@include line-h(1.2);
		b {
			display: block;
			margin-bottom: 0.2em;
			@include media-breakpoint-up(md) {
				@include f-s_md(0.94, 6);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(24);
			}
		}
		em {
			font-style: normal;
			display: block;
		}
	}
	&__intro {
		display: flex;
		margin-bottom: clamp(1.5rem, 2vw, 2rem);
		@include media-breakpoint-up(xs) {
			justify-content: center;
			column-gap: 6%;
		}
		@include media-breakpoint-down(xxs) {
			flex-direction: column;
			row-gap: 1em;
		}
	}
	&__img {
		margin: 0;
		width: 100%;
		@include media-breakpoint-up(xs) {
			width: 54%;
		}
		&__inner {
			@include radius_m;
			overflow: hidden;
		}
	}
	&__list {
		width: 100%;
		@include flex-column;
		row-gap: 0.5em;
		@include media-breakpoint-up(xs) {
			width: 40%;
		}
		@include media-breakpoint-up(xs) {
			a {
				font-size: 0.9em;
			}
		}
	}
	&__catalog {
		a {
			@include btn_base;
			padding: 0.75em 2%;
			width: 100%;
			@include radius(999px);
			border: 1px solid $d-gray;
			background: $p-gray;
			text-align: center;
			color: $d-gray;
			min-height: 44px;
			span {
				vertical-align: top;
				@include icon(pdf);
				&::before {
					font-size: 1.5em;
					margin-right: 0.2em;
					@include transition;
				}
			}
			&:hover {
				background: $b-gray;
				color: $l-gray;
				border-color: $l-gray;
				span {
					&::before {
						color: $l-gray;
					}
				}
			}
		}
	}
	&__link {
		a {
			@include btn_base;
			padding: 0.75em 2%;
			width: 100%;
			@include radius(999px);
			border: 1px solid $main_c;
			background: $main_c;
			text-align: center;
			color: $white;
			min-height: 44px;
			&:hover {
				background: $white;
				color: $main_c;
				border-color: $main_c;
			}
		}
	}
}

.pumpcar-box__detail {
	&__table {
		width: 100%;
		@include radius_s;
		border-collapse: separate;
		overflow: hidden;
		border: 1px solid $other_c3;
		@include line-h(1.5);
		tr {
			&:first-child {
			}
			&:last-child {
				th,
				td {
					border-bottom: none;
				}
			}
		}
		th {
			vertical-align: middle;
			text-align: left;
			padding: 0.5em 0.75em;
			border-bottom: 1px solid $other_c3;
			border-right: 1px solid $other_c3;
			background: $bg_c2;
			width: 30%;
			font-size: 0.84em;
			@include media-breakpoint-down(xs) {
				width: 35%;
				font-size: 0.8em;
			}
			&.border-none {
				border-bottom: none;
			}
		}
		td {
			padding: 0.5em 0.75em;
			border-bottom: 1px solid $other_c3;
			font-size: 0.84em;
			@include media-breakpoint-down(xs) {
				font-size: 0.8em;
			}
			&.is-common {
				background: $white;
				width: auto;
			}
			&.is-sub {
				background: $bg_c1;
				width: 30%;
			}
		}
	}
}
