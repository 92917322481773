@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	sales
/--------------------------------------------------------------------*/

//sales-feature
.sales-feature {
	padding-bottom: clamp(6rem, 10vw, 10rem);
	&__list {
		counter-reset: feature-num;
		@include flex-column;
		row-gap: clamp(2rem, 4vw, 4rem);
		@include media-breakpoint-up(lg) {
			padding-top: 2em;
			row-gap: clamp(2rem, 6vw, 6rem);
		}
	}
}

//sales-flow
.sales-flow {
	padding: 0 0 clamp(4rem, 8vw, 8rem);
	margin-bottom: clamp(6rem, 9vw, 9rem);
	background: $bg_c1;
	&__inner {
		position: relative;
		padding-top: clamp(2rem, 4vw, 4rem);
	}
	&__add {
		position: absolute;
		left: 0;
		right: 0;
		bottom: calc(100% - 1em);
		@include line-h(1);
		@include f-s_xs(1.14, 14);
		text-align: center;
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
		span {
			display: inline-block;
			background: $main_c;
			color: $white;
			padding: 0.5em 1.5em;
			@include radius(999px);
			@include f-w(700);
		}
	}
	&__tit {
		@include f-s_xxs(1.25 , 24);
		text-align: center;
		@include f-w(700);
		margin-bottom: calc(1rem + 1em);
		@include media-breakpoint-up(xl) {
			@include f-size(48);
		}
	}
	&__list {
		display: flex;
		counter-reset: flow-num;
		@include media-breakpoint-up(xl) {
			padding-top: 2em;
			justify-content: space-between;
		}
		@include media-breakpoint-down(lg) {
			flex-direction: column;
			row-gap: 3rem;
		}
	}
}

.flow-box {
	position: relative;
	z-index: z-index(module, part02);
	@include media-breakpoint-up(xl) {
		width: 21.38%;
	}
	&:not(:last-child) {
		&::after {
			position: absolute;
			content: "";
			background-color: transparent;
			background-image: radial-gradient($other_c2 30%, transparent 30%);
			background-size: 16px 16px;
			@include media-breakpoint-up(xl) {
				left: 100%;
				top: calc(50% - 8px);
				width: 50%;
				height: 16px;
			}
			@include media-breakpoint-down(lg) {
				top: 100%;
				left: 2%;
				height: 50%;
				width: 16px;
			}
			@include media-breakpoint-down(xs) {
				left: calc(50% - 8px);
			}
		}
	}
	&__inner {
		position: relative;
		border: 1px solid $main_c;
		@include radius_m;
		background: $white;
		height: 100%;
		padding: 2em 5% 1.5em;
		@include media-breakpoint-between(sm,lg) {
			padding-top: 3em;
		}
		@include media-breakpoint-up(xl) {
			padding: 2.5em 5% 1.5em;
		}
		@include media-breakpoint-up(xxl) {
			padding: 2.5em 8% 1.5em;
		}
		@include media-breakpoint-between(sm, lg) {
			display: grid;
			grid-template-columns: 30% 62%;
			grid-template-rows: auto 1fr;
			column-gap: 8%;
		}
	}
}
.flow-box {
	&__num {
		position: absolute;
		bottom: calc(100% - 1.5em);
		left: 5%;
		@include line-h(1);
		font-size: 0.8em;
		@include media-breakpoint-between(sm,lg) {
			left: 5%;
		}
		@include media-breakpoint-up(xl) {
			left: 5%;
			font-size: 1em;
		}
		@include media-breakpoint-up(xxl) {
			left: 8%;
		}
		span {
			@include f-family(font02);
			@include f-w(700);
			background: $main_c;
			color: $white;
			display: inline-block;
			padding: 0.5em 1.5em;
			@include radius(999px);
			&::after {
				margin-left: 0.2em;
				counter-increment: flow-num;
				content: counter(flow-num, decimal-leading-zero);
				font-size: 2em;
			}
		}
	}
}
.flow-box {
	&__tit {
		@include f-w(700);
		margin-bottom: calc(0.5rem + 0.5em);
		@include f-s_xxs(1.14, 6);
		text-align: center;
		@include media-breakpoint-between(sm, lg) {
			grid-column: 1 / 3;
			row-gap: 1 / 2;
			@include f-s_sm(1.25, 10);
			padding-left: .3em;
			text-align: left;
		}
		@include media-breakpoint-up(xl) {
			@include f-s_xl(1.14, 4);
		}
		@include media-breakpoint-up(xxl) {
			@include f-size(22);
		}
	}
	&__img {
		margin-bottom: 1em;
		&__inner {
			margin: 0;
			@include aspect-img(2, 1);
		}
	}
}
