@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	blog
/--------------------------------------------------------------------*/

.contents-flex {
	display: flex;
	@include media-breakpoint-up(lg) {
		justify-content: space-between;
	}
	@include media-breakpoint-down(md) {
		flex-direction: column;
		row-gap: clamp(4rem, 9vw, 9rem);
	}
}

.contents-left {
	width: 100%;
	@include media-breakpoint-up(lg) {
		width: calc(96% - 300px);
	}
	@include media-breakpoint-up(xl) {
		width: 72%;
	}
}

/*--------------------------------------------------------------------/
	single-page
/--------------------------------------------------------------------*/

.single-main {
	margin-bottom: clamp(2rem, 3vw, 3rem);
	&__img {
		@include radius_m;
		overflow: hidden;
		z-index: z-index(module, part01);
	}
	figcaption {
		margin-top: 0.75em;
		margin-left: 0.2em;
		@include line-h(1.5);
		font-size: 0.9em;
		padding-left: 1.5em;
		position: relative;
		&::before {
			content: "●";
			font-size: 0.8em;
			position: absolute;
			top: 0.2em;
			left: 0;
			color: $main_c;
		}
	}
}

.single-cont {
	&__txt {
		margin-bottom: 4rem;
		@include clearfix;
		@include media-breakpoint-up(md) {
			margin-bottom: 6rem;
		}
	}
	& .title {
		border-top: 1px solid $main_c;
		padding-top: 1em;
		padding-bottom: 1em;
		@include f-w(700);
		@include f-s_xs(1.14, 8);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1.25, 6);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(26);
		}
	}
}

.single-cont {
	.txt {
		p {
			margin-bottom: clamp(1rem, 3vw, 3rem);
		}
	}
	&__foot {
		border-top: 1px solid $m-gray;
		padding-bottom: 1em;
	}
	&__addtoany {
		padding-top: 1.5em;
	}
	&__tag {
		padding-top: 1em;
		a {
			font-size: 1rem !important;
			color: $txt_c;
			padding-left: 1.5em;
			margin-right: 0.5rem;
			position: relative;
			@include icon(tag, before);
			@include dec-none;
			&::before {
				position: absolute;
				top: 0.6em;
				left: 0.2rem;
				font-size: 0.85em;
				color: $main_c;
				@include dec-none;
			}
			&:hover {
				color: $main_c;
				@include dec-line;
			}
		}
	}
}

// topics-interview
.topics-interview {
	padding-bottom: clamp(3rem, 5vw, 5rem);
	&__flex {
		display: flex;
		@include media-breakpoint-up(md) {
			justify-content: space-between;
			&.is-right {
				flex-direction: row-reverse;
			}
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 0.75rem;
		}
		.topics-interview__img {
			@include media-breakpoint-up(md) {
				width: 38%;
			}
			&__inner {
				border-radius: 8px;
				overflow: hidden;
			}
		}
		.topics-interview__txt {
			@include media-breakpoint-up(md) {
				width: 58%;
			}
		}
	}
}

// topics-video
.topics-video {
	padding: clamp(1.5em, 2vw, 3em) 5%;
	margin-bottom: clamp(3rem, 5vw, 5rem);
	background: $bg_c1;
	@include radius_m;
	&__inner {
		@include media-breakpoint-up(md) {
			display: grid;
			grid-template-columns: 50% 46.5%;
			grid-template-rows: auto 1fr;
			column-gap: 4.5%;
		}
	}
	&__tit {
		margin-bottom: calc(1rem + 0.3em);
		@include f-s_xs(1, 8);
		@include f-w(700);
		position: relative;
		@include media-breakpoint-up(md) {
			@include f-s_md(1.07, 5);
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(22);
		}
	}
	&__video {
		@include media-breakpoint-up(md) {
			grid-column: 2 / 3;
			grid-row: 1 / 3;
		}
		@include media-breakpoint-down(sm) {
			@include m-a;
			max-width: 480px;
			margin-bottom: 1rem;
		}
		&__inner {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
			border-radius: 8px;
			overflow: hidden;
			z-index: z-index(module, part04);
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
		}
	}
}

/* topics-table */
.topics-table {
	padding-bottom: clamp(3rem, 5vw, 5rem);
	& .title {
		padding: 1em 2%;
		text-align: center;
		border-top: 1px solid $main_c;
		@include f-s_xs(1, 8);
		@include f-w(600);
		margin-bottom: calc(1rem + 0.3em);
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
}

// block editor
.wp-block-gallery {
	margin-bottom: 2em;
}
.wp-block-image {
	overflow: hidden;
	@include radius_m;
}
.wp-block-file {
	margin-top: 3em;
	margin-bottom: 3em;
	a {
		padding-left: 1.5em;
		padding-right: 1em;
		color: $txt_c;
		@include f-w(700);
		@include dec-none;
		@include transition;
		background: $white;
		position: relative;
		@include media-breakpoint-up(md) {
			font-size: 1.14em;
		}
		&::before {
			content: "●";
			color: $main_c;
			position: absolute;
			top: 0.15em;
			left: 0;
			font-size: 0.8em;
		}
	}
	&__button {
		padding: 0.75em 1.25em;
		border: 1px solid $main_c;
		color: $white !important;
		@include f-w(700);
		@include dec-none;
		@include transition;
		@include radius(999px);
		background: $main_c !important;
		@include media-breakpoint-up(md) {
			font-size: 0.8rem !important;
		}
		&::before {
			display: none;
		}
		&:hover {
			color: $main_c !important;
			background: $white !important;
		}
	}
}
.wp-block-group {
	margin-bottom: clamp(3rem, 6vw, 6rem) !important;
}

.wp-block-columns {
	margin-bottom: clamp(3rem, 6vw, 6rem) !important;
	h2 {
		margin-bottom: 1em !important;
	}
}
