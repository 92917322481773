@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	contact_form
/--------------------------------------------------------------------*/

.contact-intro {
	margin-bottom: clamp(3rem, 6vw, 6rem);
}

.contact-box {
	padding: 2em 5% 2.5em;
	margin-bottom: clamp(3rem, 8vw, 8rem);
	@include radius_l;
	border: 1px solid $m-gray;
	background: $white;
	@include media-breakpoint-up(lg) {
		padding: 3.5em 9% 4em;
	}
}

.contact-box__tel {
	display: flex;
	width: 100%;
	margin-bottom: 1em;
	@include media-breakpoint-up(md) {
		justify-content: space-between;
		align-items: center;
	}
	@include media-breakpoint-down(sm) {
		flex-direction: column;
		align-items: center;
		row-gap: .25em;
	}
	&__tit {
		color: $txt_c;
		overflow: hidden;
		@include f-s_xs(1.14,6);
		@include media-breakpoint-up(md) {
			@include f-s_md(1.5, 4);
			width: 52%;
			column-gap: 4%;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
		span {
			position: relative;
			@include media-breakpoint-up(md) {
				display: inline-block;
				padding-right: 0.5em;
				&::after {
					content: "";
					width: 100%;
					height: 1px;
					background: $main_c;
					position: absolute;
					top: 50%;
					left: 100%;
				}
			}
		}
	}
	&__num {
		@include f-family(font02);
		@include icon(phone);
		@include f-s_xs(1.75, 20);
		@include l-sp(0);
		position: relative;
		@include media-breakpoint-up(md) {
			@include f-s_md(2, 14);
			width: 44%;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(44);
		}
		&::before {
			@include f-em(10);
			color: $main_c;
			padding-right: 0.2em;
		}
		a {
			color: $txt_c;
		}
	}
}

.contact-box__line {
	display: flex;
	width: 100%;
	background: $bg_green;
	@include radius_m;
	padding: 1.5em 2em;
	@include media-breakpoint-up(md) {
		justify-content: space-between;
		align-items: center;
	}
	@include media-breakpoint-down(sm) {
		flex-direction: column;
		align-items: center;
		row-gap: .75em;
	}
	&__tit {
		color: $line_c;
		@include f-s_xs(1.14,6);
		@include media-breakpoint-up(md) {
			@include f-s_md(1.14, 6);
			width: 50%;
			column-gap: 4%;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(22);
		}
		span {
			position: relative;
			display: inline-block;
			padding-right: 0.5em;
			&::after {
				content: "";
				width: 100%;
				height: 1px;
				background: $main_c;
				position: absolute;
				top: 50%;
				left: 100%;
			}
		}
	}
	&__btn {
		@include media-breakpoint-up(md) {
			width: 42%;
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
		a {
			color: $white;
			@include f-family(font02);
			@include f-s_xs(1, 8);
			@include l-sp(0);
			position: relative;
			background: $line_c;
			display: flex;
			justify-content: center;
			align-items: center;
			column-gap: 0.5em;
			padding: 0.5em 5%;
			@include radius(999px);
			@include transition;
			width: 100%;
			@include dec-none;
			@include media-breakpoint-up(md) {
				@include f-s_md(1.14, 6);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(24);
			}
			&:hover {
				background: lighten($line_c, 10%);
			}
		}
	}
}

.contact-privacy {
	border: 1px solid $m-gray;
	@include radius_m;
	background: $white;
	@include media-breakpoint-up(lg) {
		padding: 3rem 6% 0;
	}
	&__inner {
		@include media-breakpoint-up(lg) {
			margin-top: 1.5rem;
			padding-bottom: 3rem;
			overflow-y: scroll;
			@include scroll-bar;
			max-height: 300px;
		}
	}
	&__tit {
		@include f-s_xs(1.14, 4);
		@include f-w(700);
		text-align: center;
		position: relative;
		@include media-breakpoint-up(lg) {
			margin-bottom: 3rem;
			padding-bottom: 1rem;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(22);
		}
		@include media-breakpoint-down(md) {
			padding: 1.5rem 6%;
			cursor: pointer;
			position: relative;
			@include icon(down_arrow, after);
			&::after {
				position: absolute;
				right: 1rem;
				color: $gray;
				top: calc(50% - 10px);
				@include transition;
				@include f-size(24);
			}
		}
		&::before {
			@include media-breakpoint-up(lg) {
				content: "";
				position: absolute;
				left: calc(50% - 3rem);
				bottom: 0;
				width: 6rem;
				height: 4px;
				@include radius(2px);
				background: $main_c;
			}
		}
		&.active {
			&::after {
				transform: rotate(180deg);
			}
		}
	}
	&__txt {
		padding: 0 2rem 2rem;
		@include media-breakpoint-up(lg) {
			padding-bottom: 3rem;
		}
	}
	.txt {
		@include f-em(15);
	}
}

.contact-form__tit {
	text-align: center;
	@include f-family(font02);
	color: $main_c;
	@include f-w(700);
	margin-bottom: calc(1rem + .5em);
	@include f-s_xs(1.5, 16);
	@include media-breakpoint-up(xl) {
		@include f-size(40);
	}
}

.contact-table {
	width: 100%;
	@include m-a;
	margin-bottom: 2rem;
	line-height: 1.2;
	position: relative;
	& th,
	& td {
		text-align: left;
		@include media-breakpoint-up(lg) {
			display: table-cell;
			padding: 1rem 0 1.5rem;
		}
		@include media-breakpoint-down(md) {
			display: block;
			padding: 0;
		}
	}
	& th {
		vertical-align: top;
		@include f-w(500);
		@include media-breakpoint-up(lg) {
			width: 30%;
		}
		& .required {
			margin-left: 0.5rem;
		}
	}
	& td {
		@include media-breakpoint-up(lg) {
			width: auto;
		}
		@include media-breakpoint-down(md) {
			padding: 1rem 0 2rem;
		}
	}
	& textarea {
		width: 100%;
	}
}

input,
select {
	width: auto;
	@include f-family(font01);
	&.long {
		width: 100%;
	}
	&.middle {
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 70%;
		}
	}
	&.short {
		width: 70%;
		@include media-breakpoint-up(md) {
			width: 30%;
		}
	}
	&.mini {
		width: 6em;
		@include media-breakpoint-up(sm) {
			width: 8em;
		}
	}
}

button[class*="sub-btn"],
button[class*="back-btn"],
button[class*="chack-btn"] {
	@include f-size(18);
	@include l-sp(0.1em);
	@include f-family(font01);
	text-align: left;
	outline: none;
}
button[class*="sub-btn"] {
	@include btn_arrow($white, $main_c, $main_c, $white, $hover_c, $hover_c);
}
button[class*="back-btn"] {
	margin-top: 1rem;
	@include btn_arrow($white, $d-gray, $d-gray, $white, $hover_c, $hover_c);
}
button[class*="chack-btn"] {
	@include btn_arrow($white, $main_c, $main_c, $white, $hover_c, $hover_c);
}

/* form-parts
----------------------------------------------------------------*/
%form-parts {
	background-color: $o-white;
	padding: 1em 1.5em;
	border: 1px solid $m-gray;
	@include radius(4px);
	line-height: 1.5;
	&:focus {
		background-color: $white;
		box-shadow: rgba(58, 134, 232, 0.25) 0 0 4px 1px;
	}
}

%placeholder {
	color: $gray;
}

input {
	@extend %form-parts;
	&::-webkit-input-placeholder {
		@extend %placeholder;
	}
	&:-moz-placeholder {
		@extend %placeholder;
	}
	&:-ms-input-placeholder {
		@extend %placeholder;
	}
}
textarea {
	@extend %form-parts;
	height: 10em;
}
select {
	@extend %form-parts;
}

/* radio-box,check-box
----------------------------------------------------------------*/
/* variablea */
$radio_height: 20px; //チェックボックスの高さ
$duration_radio: 0.4s; //チェックボックスの「「アニメーションのスピード
$checkbox_height: 26px; //チェックボックスの高さ
$duration_checkbox: 0.4s; //チェックボックスの「「アニメーションのスピード

/* radio-btn */
.mwform-radio-field {
	cursor: pointer;
	&:not(:last-child) {
		margin-right: 1em;
	}
}
.mwform-radio-field-text {
	vertical-align: top;
	position: relative;
	padding-left: 34px;
	display: block;
}
input[type="radio"] {
	position: absolute;
	opacity: 0;
	+ .mwform-radio-field-text {
		&:before {
			content: "";
			background: $o-white;
			border-radius: 100%;
			border: 1px solid $gray;
			display: inline-block;
			width: $radio_height;
			height: $radio_height;
			position: absolute;
			top: calc(50% - calc(#{$radio_height}/ 2));
			left: 0;
			vertical-align: top;
			cursor: pointer;
			text-align: center;
			transition: all $duration_radio ease;
		}
	}
	&:checked {
		+ .mwform-radio-field-text {
			&:before {
				background-color: $main_c;
				box-shadow: inset 0 0 0 5px $p-gray;
			}
		}
	}
	&:focus {
		+ .mwform-radio-field-text {
			&:before {
				outline: none;
				border-color: $main_c;
			}
		}
	}
	&:disabled {
		+ .mwform-radio-field-text {
			&:before {
				box-shadow: inset 0 0 0 4px $gray;
				border-color: $gray;
				background: $o-white;
			}
		}
	}
	+ .mwform-radio-field-text {
		&:empty {
			&:before {
				margin-right: 0;
			}
		}
	}
}
.vertical-item:not(:first-of-type) {
	margin-top: 1rem !important;
}

/* chack-btn */
@-moz-keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: calc($checkbox_height/2);
	}
}

@-webkit-keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: calc($checkbox_height/2);
	}
}

@keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: calc($checkbox_height/2);
	}
}

@keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}
@-webkit-keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}
@-moz-keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}

input[type="checkbox"] {
	display: none;
}

.check-box {
	& .vertical-item:not(:first-of-type) {
		margin-top: 1.5rem !important;
	}
	& label {
		position: relative;
		cursor: pointer;
		&::before {
			content: "";
			height: $checkbox_height;
			width: $checkbox_height;
			background-color: transparent;
			border: 1px solid $gray;
			margin-right: 1em;
			position: absolute;
			top: -0.15em;
			left: 0;
			display: block;
			transition: border-color ease calc($duration_checkbox/2);
			z-index: z-index(module, part04);
		}
	}
}

.mwform-checkbox-field-text {
	vertical-align: top;
	padding-left: calc(#{$checkbox_height} + 1rem);
	display: block;
	&::before,
	&::after {
		position: absolute;
		height: 0;
		width: $checkbox_height * 0.2;
		background-color: $main_c;
		display: inline-block;
		transform-origin: left top;
		content: "";
		transition: opacity ease 0.5;
		z-index: z-index(module, part03);
	}
	&::before {
		top: $checkbox_height * 0.86;
		left: $checkbox_height * 0.4;
		transform: rotate(-135deg);
	}
	&::after {
		top: $checkbox_height * 0.5;
		left: $checkbox_height * 0.03;
		transform: rotate(-45deg);
	}
}

input[type="checkbox"]:checked + .mwform-checkbox-field-text,
.mwform-checkbox-field-text.checked {
	border-color: $main_c;
	&::after {
		height: $checkbox_height * 0.5;
		animation: dothabottomcheck calc($duration_checkbox/2) ease 0s forwards;
	}

	&::before {
		height: $checkbox_height * 1.2;
		animation: dothatopcheck $duration_checkbox ease 0s forwards;
		box-shadow: 0 0 0 $checkbox_height * 0.05 $white;
	}
}

.mw_wp_form .error {
	margin-top: 0.7rem;
}
