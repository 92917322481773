//===================================================
//	color
//===================================================
// メインカラー
$main_c:    #C30D23;//メインカラー
$sub_c1:    #EDEEF6;//サブカラー1
$sub_c2:    #F3F3F3;//サブカラー2
$point_c:   $main_c;//ポイントカラー
$hover_c:   #e3021d;//ホバーカラー
$txt_c:     #202020;//テキストカラー
$link_c:    $main_c;//リンク基本カラー

// other color
$other_c1:  #E4FDD1;//アザーカラー1
$other_c2:  #FF8594;//アザーカラー2
$other_c3:  #d46c79;//アザーカラー2
$line_c: #00B900;//ラインカラー

// backgraound
$bg_c1:   #FDF7F7;//メインバックグラウンドカラーー
$bg_c2:   #FFD9DE;//サブバックグラウンドカラーー
$bg_green: #ECFFEC;
$bg_navy: #ebedf2;

// filter color
$filter_c:#320E0E;

// mono tone
$white:     #FFFFFF;   //ホワイト
$o-white:   #FCFCFC;//オフホワイト
$p-gray:    #E2E6E9;   //ペールグレー
$l-gray:    #D5DEE6;   //ライトグレー
$m-gray:    #B6C3CF;   //ミディアムグレー
$gray:      #979DA4;   //グレー
$dl-gray:   #6F757B;   //ダルグレー
$d-gray:    #4B4D4E;   //ダークグレー
$b-gray:    #292D2E;   //ブラックグレー
$black:     #000;   //ブラック

// color tone
$red:       $main_c;//レッド
$red2:      #D93838;//レッド2
$orange:    #ff8b2d;//オレンジ
$yellow:    #ffcf27;//イエロー
$y-green:   #01a63d;//イエローグリーン
$green:     #5d9e36;//グリーン
$s-blue:    #78CAF2;//スカイブルー
$l-blue:    #578FE4;//ライトブルー
$blue:      #2b74e0;//ブル-
$navy:      #002063;//ネイビー
$violet:    #425c93;//バイオレット
$purple:    #5c0c7a;//パープル
$pink:      #ca6a51;//ピンク

