@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	recruit
/--------------------------------------------------------------------*/

// 共通タイトル
.recruit-tit {
	text-align: center;
	line-height: 1.4;
	padding: 1em 4%;
	@include radius_m;
	margin-bottom: calc(2rem + 1.5em);
	position: relative;
	@include f-w(700);
	@include f-s_xs(1.14, 20);
	background: $main_c;
	color: $white;
	@include media-breakpoint-up(xl) {
		@include f-size(38);
	}
}

// リクナビ
.recruit-navi {
	margin-bottom: clamp(3rem, 9vw, 9rem);
	&__inner {
		@include media-breakpoint-up(md) {
			@include radius(999px);
			padding: 2em 1.5em;
			border: 1px solid $main_c;
		}
		ul {
			display: flex;
			justify-content: space-around;
			flex-wrap: wrap;
			row-gap: 1em;
			@include media-breakpoint-up(md) {
				column-gap: 0.2em;
			}
			@include media-breakpoint-down(sm) {
				flex-direction: column;
				padding: 1em;
			}
			li {
				@include line-h(1);
				a {
					@include dec-none;
					@include f-w(700);
					padding-right: 1.75em;
					@include icon(down_arrow, after);
					position: relative;
					@include transition;
					@include f-s_xxs(1, 4);
					@include media-breakpoint-up(md) {
						@include f-s_md(0.82,5);
					}
					@include media-breakpoint-up(xl) {
						@include f-em(17);
					}
					@include media-breakpoint-down(sm) {
						padding: 1em 3em 1em 1.75em;
						background: $main_c;
						color: $white;
						display: block;
						@include radius(999px);
					}
					&::after {
						position: absolute;
						@include f-em(20);
						@include transition;
						@include media-breakpoint-up(md) {
							right: 0;
							top: 0.2em;
						}
						@include media-breakpoint-down(sm) {
							top: 0.6em;
							right: 1em;
						}
					}
					&:hover {
						&::after {
							@include media-breakpoint-up(md) {
								color: $hover_c;
								top: 0.4em;
							}
							@include media-breakpoint-down(sm) {
								background: $hover_c;
								top: 0.8em;
							}
						}
					}
				}
			}
		}
	}
}

//recruit-message
.recruit-message {
	padding-bottom: clamp(9rem, 14vw, 14rem);
}

.recruit-message__intro {
	padding-bottom: clamp(5rem, 8vw, 8rem);
	position: relative;
	@include media-breakpoint-up(md) {
		padding-bottom: clamp(8rem, 10vw, 11rem);
	}
	&::before {
		content: "";
		background: $main_c;
		position: absolute;
		height: calc(100% - 5rem);
		right: 0;
		bottom: 0;
		transform-origin: top right;
		z-index: z-index(module, part01);
		border-top-left-radius: clamp(64px, 8.533vw, 128px);
		border-bottom-left-radius: clamp(64px, 8.533vw, 128px);
		width: 95%;
		@include media-breakpoint-up(md) {
			height: 60%;
			width: 92%;
		}
		@include media-breakpoint-up(xl) {
			width: calc(50% + 590px);
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
		background: $white;
		padding-top: clamp(3rem, 6vw, 6rem);
		padding-bottom: clamp(3rem, 8vw, 8rem);
		border-bottom-right-radius: clamp(64px, 8.533vw, 128px);
		width: 95%;
		padding-left: 5%;
		@include media-breakpoint-up(md) {
			display: flex;
			justify-content: flex-end;
			width: 92%;
			padding-left: 8%;
			padding-bottom: clamp(3rem, 11vw, 11rem);
		}
		@include media-breakpoint-up(xl) {
			min-height: 500px;
			width: calc(50% + 590px);
		}
		@include media-breakpoint-down(sm) {
			padding-right: 5%;
		}
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			background: $main_c;
			width: 80%;
			height: 1px;
			@include media-breakpoint-up(md) {
				width: 48.4375vw;
			}
		}
		&__inner {
			width: 100%;
			max-width: 1280px;
			position: relative;
			@include media-breakpoint-down(sm) {
				@include flex-c-reverse;
				row-gap: 1em;
			}
		}
	}
}

.recruit-message__txt {
	@include media-breakpoint-up(md) {
		width: 48.4375%;
		@include line-h(2);
	}
	@include media-breakpoint-up(xl) {
		@include f-size(17);
	}
}

.recruit-message__tit {
	@include f-w(700);
	@include f-s_xs(1.5, 12);
	margin-bottom: calc(1rem + 0.75em);
	@include media-breakpoint-up(md) {
		@include f-s_md(1.5, 12);
	}
	@include media-breakpoint-up(xl) {
		@include f-size(36);
	}
}

.recruit-message__img {
	@include media-breakpoint-up(md) {
		position: absolute;
		top: 4vw;
		right: -5%;
		width: 50%;
	}
	@include media-breakpoint-up(xl) {
		top: 2rem;
		right: -12.5%;
		max-width: 700px;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
		@include m-a;
	}
	&__inner {
		@include radius_m;
		position: relative;
		overflow: hidden;
		margin: 0;
		z-index: 1;
		@include filter_bg($black, 0.2, after);
		&::after {
			@include transition;
		}
		&:hover {
			&::after {
				background: rgba($black, 0.5);
			}
		}
	}
	i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: $white;
		@include f-s_xs(3, 32);
		z-index: z-index(module, part03);
		@include media-breakpoint-up(md) {
			@include f-s_md(3, 32);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(80);
		}
	}
}

.recruit-message__sub {
	margin-top: clamp(4rem, 6vw, 6rem);
	margin-bottom: clamp(4rem, 6vw, 6rem);
	@include radius_m;
	border: 1px solid $main_c;
	padding: 1.5em 5% 2em;
	@include media-breakpoint-up(lg) {
		padding: 2.5em 4% 3em;
	}
	&__inner {
		display: flex;
		@include media-breakpoint-up(lg) {
			justify-content: space-between;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: clamp(1.5rem, 2vw, 2rem);
		}
	}
	&__tit {
		@include f-s_xxs(1.14, 14);
		@include f-w(700);
		@include media-breakpoint-up(lg) {
			flex-basis: 30%;
			flex-shrink: 0;
			@include f-s_lg(1.14, 8);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(28);
		}
		@include media-breakpoint-down(md) {
			text-align: center;
		}
		span {
			display: inline-block;
			position: relative;
			padding-bottom: 1em;
			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				background: $main_c;
				width: 100%;
				height: 2px;
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			flex-basis: 64%;
			flex-shrink: 0;
		}
	}
}

.recruit-message__promise {
	&__tit {
		@include f-s_xxs(1.25, 12);
		@include f-w(700);
		text-align: center;
		margin-bottom: calc(2em + 1.5rem);
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
		span {
			color: $main_c;
		}
		b {
			font-size: 1.5em;
		}
	}
	&__list {
		display: flex;
		@include media-breakpoint-up(xl) {
			justify-content: space-between;
		}
		@include media-breakpoint-down(lg) {
			flex-direction: column;
			row-gap: clamp(4rem, 6vw, 6rem);
		}
	}
}

.promise-box {
	@include media-breakpoint-up(xl) {
		width: 30.9%;
	}
	&__inner {
		background: $bg_c1;
		@include radius_m;
		padding: 3.5em 5% 2em;
		position: relative;
		@include media-breakpoint-up(md) {
			padding: 4em 8% 2em;
		}
	}
	&__num {
		position: absolute;
		bottom: calc(100% - 0.4em);
		color: $main_c;
		@include f-w(700);
		@include f-family(font02);
		@include l-sp(0);
		@include line-h(1);
		@include f-s_xxs(2.5, 20);
		@include media-breakpoint-up(xl) {
			@include f-size(60);
		}
		span {
			position: relative;
			padding-bottom: 0.1em;
			&::after {
				content: "";
				position: absolute;
				left: 2.5%;
				bottom: -3px;
				height: 4px;
				width: 95%;
				background: $main_c;
				@include media-breakpoint-up(md) {
					height: 6px;
				}
			}
		}
	}
	&__tit {
		@include f-w(700);
		margin-bottom: calc(0.5rem + 0.5em);
		@include f-s_xxs(1, 8);
		@include media-breakpoint-up(xl) {
			@include f-s_xl(1.14, 2);
		}
		@include media-breakpoint-up(xxl) {
			@include f-size(20);
		}
	}
}

//recruit-environment
.recruit-environment {
	padding-bottom: clamp(9rem, 14vw, 14rem);
	position: relative;
	&::before {
		position: absolute;
		width: 100%;
		height: 10rem;
		left: 0;
		top: calc(1.5rem + 1em + 64px);
		content: "";
		background: $bg_c1;
		@include media-breakpoint-up(xl) {
			top: calc(9rem + 1em);
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part01);
	}
	&__list {
		background: $bg_c1;
		position: relative;
		padding-bottom: clamp(3rem, 9vw, 9rem);
		&__inner {
			display: flex;
			@include media-breakpoint-up(md) {
				flex-wrap: wrap;
				column-gap: 3.62%;
				row-gap: clamp(3rem, 6vw, 6rem);
			}
			@include media-breakpoint-down(sm) {
				flex-direction: column;
				row-gap: clamp(2rem, 3vw, 3rem);
			}
			& > * {
				@include media-breakpoint-up(md) {
					width: 48.19%;
				}
			}
		}
	}
}

.environment-box {
	&__inner {
		@include flex-column;
		height: 100%;
	}
	&__tit {
		background: $main_c;
		color: $white;
		padding: 1em 5%;
		border-top-left-radius: clamp(8px, 1.06vw, 16px);
		border-top-right-radius: clamp(8px, 1.06vw, 16px);
		@include media-breakpoint-up(md) {
			@include f-s_md(1, 3);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
	&__txt {
		padding: 1.5em 5% 2em;
		border-bottom-left-radius: clamp(8px, 1.06vw, 16px);
		border-bottom-right-radius: clamp(8px, 1.06vw, 16px);
		background: $white;
		flex-shrink: 1;
		height: 100%;
		&__flex {
			display: flex;
			@include media-breakpoint-up(sm) {
				justify-content: space-between;
			}
			@include media-breakpoint-between(md, lg) {
				flex-direction: column;
				row-gap: 1em;
			}
			@include media-breakpoint-down(xs) {
				flex-direction: column;
				row-gap: 1em;
			}
		}
		&__img {
			margin: 0;
			@include media-breakpoint-up(sm) {
				width: 42%;
			}
			@include media-breakpoint-between(md, lg) {
				width: 100%;
			}
			@include media-breakpoint-down(xs) {
				width: 100%;
			}
			&__inner {
				@include radius_m;
				overflow: hidden;
			}
		}
		&__txt {
			@include media-breakpoint-up(sm) {
				width: 54%;
			}
			@include media-breakpoint-between(md, lg) {
				width: 100%;
			}
			@include media-breakpoint-down(xs) {
				width: 100%;
			}
		}
	}
}

.recruit-environment__hinode {
	padding-top: clamp(3rem, 6vw, 6rem);
	&__tit {
		position: relative;
		@include f-s_xs(1.14, 16);
		@include f-w(700);
		margin-bottom: calc(1em + 1rem);
		text-align: center;
		@include media-breakpoint-up(xl) {
			@include f-size(36);
		}
		span {
			position: relative;
			display: inline-block;
			padding-bottom: 0.75em;
			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 20%;
				background: $main_c;
				width: 60%;
				height: 4px;
				@include radius(3px);
			}
		}
	}
	&__intro {
		margin-bottom: clamp(3rem, 6vw, 6rem);
		@include media-breakpoint-up(md) {
			@include line-h(2);
			@include f-w(500);
		}
		@include media-breakpoint-up(lg) {
			@include f-size(18);
		}
	}
}

.recruit-environment__hinode__sub {
	padding: 2em 5% 2em;
	@include radius_m;
	border: 1px solid;
	background: $white;
	@include media-breakpoint-up(lg) {
		padding: 3em 5% 3.5em;
	}
	&.is-hinode {
		border-color: $green;
		.recruit-environment__hinode__sub__tit {
			color: $green;
		}
		.recruit-environment__hinode__sub__list {
			counter-reset: hinode-num;
		}
		.recruit-environment__hinode__sub__item {
			&::before {
				counter-increment: hinode-num;
				content: counter(hinode-num, decimal-leading-zero);
				color: $green;
			}
		}
	}
	&.is-tokyo {
		margin-top: clamp(2rem, 4vw, 4rem);
		border-color: $navy;
		.recruit-environment__hinode__sub__tit {
			color: $navy;
		}
		.recruit-environment__hinode__sub__list {
			counter-reset: tokyo-num;
		}
		.recruit-environment__hinode__sub__item {
			&::before {
				counter-increment: tokyo-num;
				content: counter(tokyo-num, decimal-leading-zero);
				color: $navy;
			}
		}
	}
	&__tit {
		text-align: center;
		@include f-s_xxs(1, 8);
		@include f-w(700);
		margin-bottom: calc(1rem + 0.5em);
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
	&__list {
		display: flex;
		justify-content: center;
	}
	&__items {
		@include flex-column;
		row-gap: 1em;
	}
	&__item {
		padding-left: 2em;
		position: relative;
		@include f-w(500);
		@include f-s_xxs(1, 4);
		@include media-breakpoint-up(lg) {
			padding-left: 2.5em;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
		&::before {
			position: absolute;
			top: 0.3em;
			left: 0;
			@include f-family(font02);
			@include f-w(700);
			@include l-sp(0);
			@include line-h(0.9);
			font-size: 1.2em;
			@include media-breakpoint-up(lg) {
				top: 0.2em;
				font-size: 1.5em;
			}
		}
	}
}

.recruit-environment__hinode__banner {
	margin-top: clamp(2rem, 3vw, 3rem);
	position: relative;
	padding: 1.5em 5%;
	@include radius_m;
	position: relative;
	@include media-breakpoint-up(lg) {
		padding: 1.5em 8%;
	}
	&.is-hinode {
		background: $bg_green;
		.recruit-environment__hinode__banner__img {
			a {
				border-color: $green;
			}
		}
	}
	&.is-tokyo {
		background: $bg_navy;
		.recruit-environment__hinode__banner__img {
			a {
				border-color: $navy;
			}
		}
	}
	&__add {
		position: absolute;
		width: clamp(60px, 18.66vw, 120px);
		@include media-breakpoint-up(lg) {
			right: 0;
			top: -2rem;
		}
		@include media-breakpoint-up(xl) {
			width: 140px;
		}
		@include media-breakpoint-down(md) {
			left: calc(100% - 12vw);
			bottom: -2rem;
		}
	}
	&__inner {
		display: flex;
		@include media-breakpoint-up(lg) {
			justify-content: center;
			align-items: center;
			column-gap: 5%;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: 1em;
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			max-width: 50%;
			width: auto;
		}
	}
	&__img {
		max-width: 400px;
		@include media-breakpoint-up(lg) {
			flex-basis: auto;
		}
		@include media-breakpoint-down(md) {
			@include m-a;
		}
		a {
			@include radius_s;
			overflow: hidden;
			display: block;
			border: 1px solid;
		}
	}
}

//recruit-voice
.recruit-voice {
	padding-bottom: clamp(9rem, 14vw, 14rem);
	&__list {
		display: flex;

		@include media-breakpoint-up(md) {
			flex-wrap: wrap;
			column-gap: 3.62%;
			row-gap: clamp(3rem, 9vw, 9rem);
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: clamp(5rem, 9vw, 9rem);
		}
		& > * {
			@include media-breakpoint-up(md) {
				width: 48.19%;
			}
		}
	}
}

.voice-box {
	border-top: 1px solid $main_c;
	&__inner {
		position: relative;
		padding-top: clamp(1rem, 1.5vw, 1.5rem);
	}
	&__tit {
		padding-bottom: clamp(1rem, 2vw, 2rem);
		b {
			padding-right: 2em;
			@include f-w(700);
			@include line-h(1.2);
			@include f-s_xxs(1.75, 16);
			@include media-breakpoint-up(md) {
				@include f-s_md(1.5, 8);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(34);
			}
			@include media-breakpoint-down(md) {
				display: block;
				margin-bottom: 0.2em;
			}
		}
		span {
			display: inline-block;
		}
	}
	&__flex {
		display: flex;
		@include media-breakpoint-up(sm) {
			justify-content: space-between;
		}
		@include media-breakpoint-only(md) {
			flex-direction: column;
			row-gap: 1em;
		}
		@include media-breakpoint-only(lg) {
			display: block;
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column;
			row-gap: 1em;
		}
	}
	&__img {
		margin: 0;
		width: 100%;
		@include media-breakpoint-up(sm) {
			width: 48.75%;
		}
		@include media-breakpoint-only(md) {
			width: 100%;
		}
		@include media-breakpoint-only(lg) {
			float: left;
			margin-right: 1em;
			margin-bottom: 1em;
		}
		&__inner {
			@include radius_m;
			overflow: hidden;
		}
	}
	&__txt {
		@include media-breakpoint-up(sm) {
			width: 45%;
		}
		@include media-breakpoint-between(md, lg) {
			width: 100%;
		}
	}
}

//recruit-application
.recruit-application {
	padding-bottom: clamp(9rem, 14vw, 14rem);
	&__txt {
		max-width: 1180px;
		margin: 0 auto;
	}
}
