@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	company
/--------------------------------------------------------------------*/

/* company-message */
.company-message {
	padding-bottom: clamp(5rem, 8vw, 8rem);
	position: relative;
	@include media-breakpoint-up(md) {
		padding-bottom: clamp(8rem, 10vw, 11rem);
	}
	&.is-chairman {
		margin-bottom: clamp(6rem, 14vw, 14rem);
	}
	&.is-president {
		margin-bottom: clamp(8rem, 18vw, 20rem);
	}
	&::before {
		content: "";
		background: $main_c;
		position: absolute;
		height: calc(100% - 5rem);
		right: 0;
		bottom: 0;
		transform-origin: top right;
		z-index: z-index(module, part01);
		border-top-left-radius: clamp(64px, 8.533vw, 128px);
		border-bottom-left-radius: clamp(64px, 8.533vw, 128px);
		width: 95%;
		@include media-breakpoint-up(md) {
			height: 60%;
			width: 92%;
		}
		@include media-breakpoint-up(xl) {
			width: calc(50% + 590px);
		}
	}
}

.company-message {
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
		background: $white;
		padding-top: clamp(3rem, 6vw, 6rem);
		padding-bottom: clamp(3rem, 8vw, 8rem);
		border-bottom-right-radius: clamp(64px, 8.533vw, 128px);
		width: 95%;
		padding-left: 5%;
		@include media-breakpoint-up(md) {
			display: flex;
			justify-content: flex-end;
			width: 92%;
			padding-left: 8%;
			padding-bottom: clamp(3rem, 11vw, 11rem);
		}
		@include media-breakpoint-up(xl) {
			min-height: 600px;

			width: calc(50% + 590px);
		}
		@include media-breakpoint-down(sm) {
			padding-right: 5%;
		}
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			background: $main_c;
			width: 80%;
			height: 1px;
			@include media-breakpoint-up(xl) {
				width: calc(100% - 480px);
			}
		}
		&__inner {
			width: 100%;
			max-width: 1180px;
			position: relative;
			@include media-breakpoint-up(xl) {
			}
			@include media-breakpoint-down(sm) {
				@include flex-c-reverse;
				row-gap: 1em;
			}
		}
	}
}

.company-message__txt {
	@include media-breakpoint-up(md) {
		width: 57.62%;
		@include line-h(2);
	}
	@include media-breakpoint-up(xl) {
		@include f-size(17);
	}
}
.company-message__tit {
	@include f-w(700);
	@include f-s_xs(1.5, 12);
	margin-bottom: calc(1rem + 0.75em);
	@include media-breakpoint-up(md) {
		@include f-s_md(1.5, 12);
	}
	@include media-breakpoint-up(xl) {
		@include f-size(36);
	}
}

.company-message__name {
	margin-top: clamp(2rem, 3vw, 3rem);
	@include f-w(700);
	@include f-s_xs(1, 8);
	@include media-breakpoint-up(md) {
		@include f-s_md(1, 8);
	}
	@include media-breakpoint-up(xl) {
		@include f-size(24);
	}
}

.company-message__img {
	@include media-breakpoint-up(md) {
		position: absolute;
		top: 4vw;
		right: -5%;
		width: 33.89%;
	}
	@include media-breakpoint-up(xl) {
		top: 5rem;
		width: 400px;
	}
	@include media-breakpoint-down(sm) {
		width: clamp(140px, 30%, 240px);
		@include m-a;
	}
	&__inner {
		@include radius_m;
		position: relative;
		overflow: hidden;
		z-index: 1;
	}
}

/* company-philosophy */
.company-philosophy {
	position: relative;
	padding-bottom: clamp(4rem, 10vw, 10rem);
	padding-top: clamp(6rem, 40vw, 24rem);
	background: $b-gray;
	@include media-breakpoint-up(lg) {
		padding-top: clamp(8rem, 36vw, 20rem);
	}
	&__bg {
		position: absolute;
		width: 100%;
		height: floor-decimal(calc(3 / 4) * 100%);
		top: 0;
		left: 0;
		z-index: z-index(module, part01);
		overflow: hidden;
		@include media-breakpoint-up(lg) {
			height: floor-decimal(calc(7 / 12) * 100%);
		}
		&__inner {
			position: relative;
			@include aspect-img(4, 3);
			@include filter_bg($white, 0.36, after);
			@include media-breakpoint-up(lg) {
				@include aspect-img(12, 7);
			}
		}
	}
}

.company-philosophy {
	&__cont {
		background: $white;
		position: relative;
		z-index: z-index(module, part01);
		@include radius_l;
		padding: 2em 5% 2.5em;
		@include media-breakpoint-up(md) {
			padding: 4em 5% 5em;
		}
		@include media-breakpoint-up(lg) {
			padding: 4em 10% 6em;
		}
	}
	&__add {
		position: absolute;
		@include line-h(1);
		@include f-family(font02);
		@include f-w(700);
		color: $main_c;
		left: 0;
		width: 100%;
		bottom: calc(100% - 0.5em);
		display: flex;
		justify-content: center;
		align-items: center;
		@include l-sp(0.02em);
		@include f-s_xs(1.5, 48);
		@include media-breakpoint-up(xl) {
			@include f-size(72);
		}
		span {
			display: block;
		}
	}
	&__tit {
		text-align: center;
		@include f-s_xs(1.75, 16);
		@include l-sp(0.2em);
		margin-bottom: calc(1rem + 0.5em);
		@include media-breakpoint-up(xl) {
			@include f-size(44);
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			@include line-h(2);
		}
	}
}

.company-philosophy__box {
	&:not(:last-child) {
		margin-bottom: clamp(2rem, 4vw, 4rem);
	}
	&__tit {
		@include f-s_xs(1.14, 4);
		@include line-h(1.4);
		margin-bottom: calc(0.5rem + 0.3em);
		@include f-w(700);
		@include media-breakpoint-up(md) {
			text-align: center;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
		b {
			color: $main_c;
		}
	}
}

// company-ozawalogo
.company-ozawalogo {
	padding: clamp(4rem, 9vw, 10rem) 0;
	position: relative;
	width: 100%;
	@include flex-end;
	&__inner {
		text-align: right;
		width: clamp(320px, 75%, 1200px);
		padding-right: 2%;
	}
}

// company-overview
.company-overview {
	position: relative;
	padding-bottom: clamp(6rem, 12vw, 12rem);
	&__inner {
		display: flex;
		@include media-breakpoint-up(lg) {
			column-gap: 5%;
			justify-content: space-between;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
	}
	&__tit {
		@include media-breakpoint-up(lg) {
			flex-basis: auto;
			flex-shrink: 0;
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			flex-basis: 65.33%;
			flex-shrink: 1;
		}
	}
}

// company-accessmap
.company-accessmap {
	position: relative;
	background-color: $white;
	&__list {
		@include flex-column;
		row-gap: clamp(5rem, 8vw, 8rem);
	}
	&__box {
		&__inner {
			@include media-breakpoint-up(md) {
				display: grid;
				grid-template-columns: 34.66% 50%;
				grid-template-rows: auto 1fr;
				column-gap: 15.34%;
			}
		}
		&__tit {
			padding-bottom: .75em;
			margin-bottom: .75em;
			border-bottom: 1px solid $main_c;
			@include f-s_xs(1.25,10);
			@include media-breakpoint-up(md) {
				padding-top: 1em;
				@include f-s_md(1.25,8);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
		&__map {
			position: relative;
			padding: 0;
			height: 0;
			overflow: hidden;
			background-color: $white;
			z-index: z-index(module,part01);
			@include radius_m;
			@include media-breakpoint-up(md) {
				padding-bottom: 60%;
				grid-column: 2 / 3;
				grid-row: 1 / 3;
			}
			@include media-breakpoint-down(sm) {
				padding-bottom: 360px;
				margin-bottom: .75em;
			}
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100% !important;
				height: 100% !important;
			}
		}
		.btn-wrap {
			@include media-breakpoint-down(sm) {
				@include m-a;
			}
		}
	}
}
