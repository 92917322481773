@charset "utf-8";
@use "_mixins/break-points" as *;
@use "base" as *;
/*====================================================================

lv2.css

=====================================================================*/

@use "_components/breadcrumb"; //パンくずリスト
@use "_lv2/pouring"; //コンクリート打設工事
@use "_lv2/sales"; //生コン販売・手配
@use "_lv2/pumpcarlist"; //小澤総業のコンクリートポンプ車
@use "_lv2/recruit"; //採用情報
@use "_lv2/company"; //会社情報
@use "_lv2/contact"; //お問い合わせ
@use "_lv2/topics"; //トピックス
@use "_lv2/aside"; //サイドバー

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
	position: relative;
	padding-top: 64px;
	padding-bottom: clamp(4rem, 6vw, 6rem);
	@include media-breakpoint-up(lg) {
		padding-bottom: clamp(6rem, 9vw, 8rem);
	}
	@include header-up {
		padding-top: 80px;
	}
	&__inner {
		position: relative;
		display: flex;
		width: 100%;
		height: 75vw;
		@include media-breakpoint-up(lg) {
			align-items: center;
			height: 54vw;
		}
		@include media-breakpoint-up(xl) {
			height: 600px;
		}
		@include media-breakpoint-up(full) {
			height: 31.25vw;
		}
		@include media-breakpoint-down(md) {
			align-items: flex-end;
		}
	}
	&::before {
		position: absolute;
		bottom: 0;
		left: 0;
		content: "";
		position: absolute;
		background: $main_c;
		width: 89.0625%;
		height: 60%;
		border-top-right-radius: clamp(64px, 8.533vw, 128px);
		border-bottom-right-radius: clamp(64px, 8.533vw, 128px);
	}
}

.main-view__bg {
	position: absolute;
	top: 0;
	right: 0;
	width: 70vw;
	z-index: z-index(module, part02);
	height: 80%;
	overflow: hidden;
	border-top-left-radius: 9999px;
	border-bottom-left-radius: 9999px;
	@include media-breakpoint-up(lg) {
		width: 59.375vw;
		height: 100%;
	}
	&__inner {
		height: 100%;
	}
}

.main-view {
	&__tit {
		background: $white;
		position: relative;
		z-index: z-index(module, part03);
		border-top-right-radius: 9999px;
		border-bottom-right-radius: 9999px;
		width: 70vw;
		padding: clamp(2em, 8vw, 8rem) clamp(2em, 9.38vw, 8rem) clamp(2em, 8vw, 8rem) 0;
		@include media-breakpoint-up(lg) {
			padding: clamp(4em, 6.4vw, 6rem) clamp(6em, 8.533vw, 8rem) clamp(4em, 6.4vw, 6rem) 0;
			width: 50vw;
		}
		@include media-breakpoint-up(xl) {
			padding: 7em 8em 7em 0;
			min-width: 760px;
			display: flex;
			align-items: center;
		}
		&__inner {
			position: relative;
			padding-left: 5vw;
			@include media-breakpoint-up(xxl) {
				padding-left: 6.77vw;
			}
		}
		@include media-breakpoint-down(md) {
			margin-bottom: clamp(2em, 4vw, 4em);
		}
		&__txt {
			&.is-404 {
				em {
					@include f-s_xxs(1.5, 30);
					@include media-breakpoint-up(lg) {
						@include f-s_lg(3, 32);
					}
					@include media-breakpoint-up(xl) {
						@include f-size(72);
					}
				}
				span {
					@include f-s_xxs(1, 16);
					@include media-breakpoint-up(xl) {
						@include f-size(32);
					}
				}
			}
			&.is-anothertype {
				em {
					@include f-s_xxs(1.25, 48);
					@include f-w(700);
					line-height: 1;
					@include f-family(font02);
					display: block;
					font-style: normal;
					color: $main_c;
					margin-bottom: 0.1em;
					@include media-breakpoint-up(lg) {
						@include f-s_lg(3, 24);
					}
					@include media-breakpoint-up(xl) {
						@include f-size(72);
					}
				}
			}
			&.is-term {
				em {
					@include f-s_xxs(1.14, 28);
					@include media-breakpoint-up(lg) {
						@include f-s_lg(2.5, 8);
					}
					@include media-breakpoint-up(xl) {
						@include f-size(48);
					}
				}
				h1 {
					@include f-s_xs(1, 16);
					@include media-breakpoint-up(xl) {
						@include f-size(32);
					}
				}
			}
			&.is-single {
				em {
					@include f-s_xxs(1.14, 28);
					@include media-breakpoint-up(lg) {
						@include f-s_lg(2.5, 8);
					}
					@include media-breakpoint-up(xl) {
						@include f-size(48);
					}
				}
				h1 {
					@include f-s_xxs(1, 16);
					@include media-breakpoint-up(xl) {
						@include f-size(32);
					}
				}
			}
			em {
				@include f-s_xs(2, 64);
				@include f-w(700);
				line-height: 1.2;
				@include f-family(font02);
				display: block;
				font-style: normal;
				color: $main_c;
				@include media-breakpoint-up(lg) {
					@include f-s_lg(4, 32);
				}
				@include media-breakpoint-up(xl) {
					@include f-size(96);
				}
			}
			h1 {
				@include l-sp(0.05em);
				@include f-w(700);
				@include f-s_xs(1.14, 20);
				padding: 0 0 0 0.1em;
				position: relative;
				display: block;
				line-height: 1.2;
				@include media-breakpoint-up(xl) {
					@include f-size(40);
				}
			}
		}
		&__date {
			margin-top: 0.5em;
			@include l-sp(0.02em);
			@include f-w(700);
			@include f-family(font02);
			color: $gray;
			@include f-s_xs(0.84, 4);
			@include media-breakpoint-up(lg) {
				@include f-s_lg(1, 4);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(20);
			}
		}
		.category-list {
			margin-top: 0.5em;
			@include media-breakpoint-up(lg) {
				margin-top: 0.75em;
			}
			& .category-item {
				line-height: 1.2;
				padding: 0.5em 1.75em;
				vertical-align: top;
				@include f-s_xs(0.75, 2);
				@include media-breakpoint-up(lg) {
					@include f-s_lg(1, 4);
				}
				@include media-breakpoint-up(xl) {
					@include f-size(20);
				}
			}
		}
	}
}

.contents {
	padding-top: clamp(4rem, 10vw, 10rem);
}

/*--------------------------------------------------------------------/
	共通パーツ
/--------------------------------------------------------------------*/
// common-intro
.common-intro {
	position: relative;
	margin-bottom: clamp(6rem, 12vw, 12rem);
	@include media-breakpoint-up(lg) {
		padding-bottom: clamp(3rem, 7vw, 7rem);
	}
	@include media-breakpoint-down(md) {
		padding-bottom: clamp(5rem, 16vw, 16rem);
	}
	&::before {
		position: absolute;
		bottom: 0;
		right: 0;
		content: "";
		width: 95%;
		height: 70%;
		background: $main_c;
		border-top-left-radius: clamp(64px, 8.533vw, 128px);
		border-bottom-left-radius: clamp(64px, 8.533vw, 128px);
		@include media-breakpoint-up(lg) {
			height: 80%;
			width: 80.73%;
		}
	}
	&__inner {
		position: relative;
	}
}

.common-intro__txt {
	position: relative;
	z-index: z-index(module, part02);
	background: $white;
	position: relative;
	padding-left: 5vw;
	padding-right: 5vw;
	@include flex-end;
	border-bottom-right-radius: clamp(64px, 8.533vw, 128px);
	@include media-breakpoint-up(lg) {
		width: 68.125%;
		padding-right: clamp(2em, 12.38vw, 12rem);
		padding-bottom: clamp(2em, 8vw, 8rem);
	}
	@include media-breakpoint-up(xl) {
		min-height: 660px;
	}
	@include media-breakpoint-up(xxl) {
		padding-left: 6.77vw;
	}
	@include media-breakpoint-down(md) {
		width: 95%;
		max-width: 800px;
		padding-bottom: clamp(2em, 48.96vw, 460px);
	}
	@include media-breakpoint-down(xs) {
		padding-bottom: clamp(2em, 54.96vw, 460px);
	}
	&__inner {
		position: relative;
		padding-top: clamp(2rem, 3vw, 3rem);
		width: 100%;
		&::before {
			position: absolute;
			top: 0;
			right: 0;
			content: "";
			width: 100vw;
			height: 1px;
			background: $main_c;
		}
	}
	&__tit {
		@include f-w(900);
		@include f-s_xxs(1, 20);
		margin-bottom: calc(1rem + 0.7em);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1.5, 6);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
		@include media-breakpoint-up(xxl) {
			@include f-size(38);
		}
		b {
			color: $main_c;
		}
		strong {
			font-size: 2em;
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			@include f-size(17);
		}
		@include media-breakpoint-up(xl) {
			max-width: 760px;
		}
		ul {
			text-align: left;
			& li {
				line-height: 1.4;
				padding-left: 1.5rem;
				position: relative;
				&::before {
					content: "●";
					color: $main_c;
					position: absolute;
					left: 0;
					top: 0.2em;
					font-size: 0.8em;
				}
				&:not(:last-child) {
					margin-bottom: 0.75em;
				}
			}
		}
	}
}

.common-intro__img {
	position: absolute;
	z-index: z-index(module, part03);
	right: 0;
	width: 80%;
	@include media-breakpoint-up(lg) {
		top: clamp(2em, 4vw, 4rem);
		width: 38.54%;
	}
	@include media-breakpoint-only(lg) {
		height: 100%;
	}
	@include media-breakpoint-up(xl) {
		height: auto;
	}
	@include media-breakpoint-down(md) {
		bottom: -8vw;
	}
	&__inner {
		margin: 0;
		border-top-left-radius: clamp(8px, 1.06vw, 16px);
		border-bottom-left-radius: clamp(8px, 1.06vw, 16px);
		overflow: hidden;
		@include media-breakpoint-only(lg) {
			height: 100%;
			& > div {
				height: 100%;
			}
		}
		@include media-breakpoint-up(xl) {
			@include aspect-img;
		}
		@include media-breakpoint-down(md) {
			@include aspect-img(16, 9);
		}
		@include media-breakpoint-down(xs) {
			@include aspect-img;
		}
	}
}
