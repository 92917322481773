@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	breadcrumb
/--------------------------------------------------------------------*/
.breadcrumb {
  position: absolute;
  top: calc(100% + 1em);
  left: 5vw;
  width: 100%;
  @include media-breakpoint-up(lg) {
    width: calc(100% - 8.533vw);
    top: calc(100% + 2em);
  }
  @include media-breakpoint-up(xxl) {
    left: 7vw;
  }
  
  &__list {
    @include flex-wrap;
    @include f-size(10);
    @include l-sp(0.01em);
    @include media-breakpoint-up(lg) {
      @include f-size(12);
    }
    li {
      position: relative;
      color: $white;
      &:not(:last-child) {
        padding-right: 1.5rem;
        @include icon(right_arrow, after);
        &::after {
          position: absolute;
          right: 0.3rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    a {
      color: $white;
      @include transition;
      vertical-align: top;
      &:first-child {
        @include dec-none;
      }
      &:hover {
        color: rgba($o-white,0.7);
      }
    }
  }
}
