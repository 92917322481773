@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	pouring
/--------------------------------------------------------------------*/

//pouring-feature
.pouring-feature {
	padding-bottom: clamp(6rem, 10vw, 10rem);
	&__list {
		counter-reset: feature-num;
		@include flex-column;
		row-gap: clamp(2rem, 4vw, 4rem);
		@include media-breakpoint-up(lg) {
			padding-top: 2em;
			row-gap: clamp(2rem, 6vw, 6rem);
		}
	}
}

.feature-box {
	position: relative;
	&__inner {
		position: relative;
		@include media-breakpoint-up(md) {
			padding-top: 3rem;
		}
	}
	&:nth-of-type(odd) {
		.feature-box__img {
			@include media-breakpoint-up(md) {
				left: 0;
			}
		}
		.feature-box__txt {
			@include media-breakpoint-up(md) {
				margin-left: 8.933%;
				display: flex;
				justify-content: flex-end;
			}
		}
	}
	&:nth-of-type(even) {
		.feature-box__img {
			@include media-breakpoint-up(md) {
				right: 0;
			}
		}
		.feature-box__txt {
			@include media-breakpoint-up(md) {
				margin-right: 8.933%;
			}
		}
	}
}
.feature-box {
	&__img {
		margin: 0;
		@include media-breakpoint-up(md) {
			width: 40%;
			position: absolute;
			top: 0;
		}
		@include media-breakpoint-down(sm) {
			width: 90%;
			@include m-a;
		}
		&__inner {
			@include radius_m;
			overflow: hidden;
			@include aspect-img;
		}
	}
	&__txt {
		@include radius_m;
		background: $bg_c1;
		@include media-breakpoint-up(md) {
			padding: 2rem 8% 4em;
		}
		@include media-breakpoint-up(lg) {
			min-height: 460px;
		}
		@include media-breakpoint-down(sm) {
			padding: 6rem 5% 1.5em;
			margin-top: -4.5rem;
		}
		&__inner {
			position: relative;
			@include media-breakpoint-up(md) {
				padding-top: 4rem;
				width: 58%;
				flex-shrink: 1;
			}
			@include media-breakpoint-down(sm) {
				padding: 2rem 5% 0;
			}
		}
		&__num {
			position: absolute;
			left: 5%;
			@include f-family(font02);
			@include f-w(700);
			z-index: z-index(module, part02);
			color: $main_c;
			display: block;
			@include f-s_xs(3, 48);
			@include media-breakpoint-up(md) {
				bottom: calc(100% - 1rem);
				left: 0;
			}
			@include media-breakpoint-up(xl) {
				@include f-size(96);
			}
			@include media-breakpoint-down(sm) {
				bottom: 100%;
			}
			span {
				display: block;
				position: relative;
				@include line-h(1);
				&::before {
					counter-increment: feature-num;
					content: counter(feature-num, decimal-leading-zero);
				}
				&::after {
					content: "";
					position: absolute;
					left: 5%;
					bottom: -8px;
					width: 90%;
					height: 4px;
					background: $main_c;
					@include media-breakpoint-up(md) {
						height: 6px;
					}
					@include media-breakpoint-up(lg) {
						height: 8px;
					}
				}
			}
		}
		&__tit {
			margin-bottom: calc(0.5rem + 0.5em);
			@include f-w(700);
			@include f-s_xs(1.14, 14);
			@include line-h(1.2);
			@include media-breakpoint-up(md) {
				@include f-s_md(1.14, 14);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
			b {
				color: $main_c;
			}
		}
		&__txt {
			ul {
				text-align: left;
				& li {
					line-height: 1.4;
					padding-left: 1.5rem;
					position: relative;
					&::before {
						content: "●";
						color: $main_c;
						position: absolute;
						left: 0;
						top: 0.2em;
						font-size: 0.8em;
					}
					&:not(:last-child) {
						margin-bottom: 0.75em;
					}
				}
			}
		}
	}
}

//pouring-construction
.pouring-construction {
	padding-bottom: clamp(6rem, 10vw, 10rem);
	.l-col-3-sm {
		@include media-breakpoint-up(lg) {
			padding-top: 2em;
		}
	}
}

.construction-box {
	position: relative;
	margin-bottom: clamp(2rem, 3vw, 3rem);
	&__inner {
		height: 100%;
		@include flex-column;
		align-items: center;
	}
	&__img {
		position: relative;
		margin: 0;
		@include aspect-img(16, 9);
		z-index: z-index(module, part02);
		@include radius_m;
		overflow: hidden;
		width: 90%;
		@include media-breakpoint-up(md) {
			width: 84%;
		}
	}
	&__txt {
		position: relative;
		z-index: z-index(module, part01);
		background: $sub_c1;
		@include radius_m;
		padding: 6rem 5% 1.5em;
		margin-top: -4.5rem;
		flex-shrink: 1;
		@include media-breakpoint-up(md) {
			padding: 6rem 8% 1.5em;
		}
		&__tit {
			@include f-w(700);
			padding-bottom: 0.75em;
			margin-bottom: calc(1em + 0.5rem);
			text-align: center;
			@include f-s_xs(1.14, 6);
			position: relative;
			@include media-breakpoint-up(sm) {
				@include f-s_sm(1.14, 6);
			}
			@include media-breakpoint-up(lg) {
				@include f-s_lg(1.14, 6);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(24);
			}
			&::before {
				content: "";
				position: absolute;
				bottom: -2px;
				left: calc(50% - 1.5rem);
				background: $main_c;
				width: 3rem;
				height: 3px;
				@include radius(3px);
			}
		}
	}
}

//pouring-results
.pouring-results {
	padding: clamp(4rem, 6vw, 6rem) 0 clamp(4rem, 8vw, 8rem);
	margin-bottom: clamp(6rem, 9vw, 9rem);
	position: relative;
	background: $sub_c1;
	&::before {
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		background: $main_c;
		width: 100%;
		height: 30%;
		@include media-breakpoint-up(lg) {
			min-height: 500px;
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
		display: flex;
		@include media-breakpoint-up(lg) {
			column-gap: 5%;
			justify-content: space-between;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
	}
	&__tit {
		margin-bottom: calc(1rem + 2em);
		@include f-s_xs(1.14, 16);
		@include media-breakpoint-up(lg) {
			flex-basis: auto;
			flex-shrink: 0;
			@include f-s_lg(1.25, 12);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
		em {
			display: block;
			font-style: normal;
			color: $white;
			@include f-w(700);
			@include f-family(font02);
			@include line-h(1);
			@include l-sp(0);
			@include f-s_xs(1.75, 48);
			padding-bottom: 0.2em;
			@include media-breakpoint-up(lg) {
				@include f-s_lg(2, 36);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(72);
			}
		}
		b {
			display: block;
			color: $white;
			@include f-w(700);
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			flex-basis: 65.33%;
			flex-shrink: 1;
		}
		&__inner {
			padding: clamp(1.5em, 3vw, 3em) 5%;
			background: $white;
			@include radius_m;
			@include media-breakpoint-up(md) {
				padding: clamp(1.5em, 3vw, 3em) 8%;
			}
		}
	}
}
