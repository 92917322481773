@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1740px) {
  /*====================================================================/
  /*--Large PC 以上--
  /====================================================================*/
}
@media (min-width: 1597px) {
  /*====================================================================/
  /*---PC 以上--
  /====================================================================*/
}
@media (min-width: 1240px) {
  /*====================================================================/
  /*--header breakpoint 以上--
  /====================================================================*/
}
@media (min-width: 1024px) {
  /*====================================================================/
  /*--XGAサイズ　iPadPro 以上--
  /====================================================================*/
}
@media (min-width: 768px) {
  /*====================================================================/
  /*--タブレット　iPadmini 以上--
  /====================================================================*/
}
@media (min-width: 576px) {
  /*====================================================================/
  /*--スマホ 以上--
  /====================================================================*/
}
@media (min-width: 376px) {
  /*====================================================================/
  /*--iPhone 以上--
  /====================================================================*/
}
@media (max-width: 1739px) {
  /*====================================================================/
  /*--Large PC 未満--
  /====================================================================*/
}
@media (max-width: 1239px) {
  /*====================================================================/
  /*--header breakpoint 未満--
  /====================================================================*/
}
@media (max-width: 1596px) {
  /*====================================================================/
  /*--PC 未満--
  /====================================================================*/
}
@media (max-width: 1023px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro 未満--
  /====================================================================*/
}
@media (max-width: 767px) {
  /*====================================================================/
  /*--タブレット iPadmini 未満--
  /====================================================================*/
}
@media (max-width: 575px) {
  /*====================================================================/
  /*--iPhone 以下--
  /====================================================================*/
}
@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
  /*--スマホ以上 タブレット iPadmini未満--
  /====================================================================*/
}
@media (min-width: 768px) and (max-width: 1023px) {
  /*====================================================================/
  /*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
  /====================================================================*/
}
@media (min-width: 1024px) and (max-width: 1596px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro以上 PC未満--
  /====================================================================*/
}
@media (min-width: 1597px) and (max-width: 1739px) {
  /*====================================================================/
  /*--px以上 Large PC未満--
  /====================================================================*/
}
/*--------------------------------------------------------------------/
	breadcrumb
/--------------------------------------------------------------------*/
.breadcrumb {
  position: absolute;
  top: calc(100% + 1em);
  left: 5vw;
  width: 100%;
}
@media (min-width: 1024px) {
  .breadcrumb {
    width: calc(100% - 8.533vw);
    top: calc(100% + 2em);
  }
}
@media (min-width: 1740px) {
  .breadcrumb {
    left: 7vw;
  }
}
.breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.63rem;
  letter-spacing: 0.01em;
}
@media (min-width: 1024px) {
  .breadcrumb__list {
    font-size: 0.75rem;
  }
}
.breadcrumb__list li {
  position: relative;
  color: #FFFFFF;
}
.breadcrumb__list li:not(:last-child) {
  padding-right: 1.5rem;
}
.breadcrumb__list li:not(:last-child):after {
  content: "\e315";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.breadcrumb__list li:not(:last-child)::after {
  position: absolute;
  right: 0.3rem;
  top: 50%;
  transform: translateY(-50%);
}
.breadcrumb__list a {
  color: #FFFFFF;
  transition: all 0.3s ease 0s;
  vertical-align: top;
}
.breadcrumb__list a:first-child {
  text-decoration: none !important;
}
.breadcrumb__list a:hover {
  color: rgba(252, 252, 252, 0.7);
}

/*--------------------------------------------------------------------/
	pouring
/--------------------------------------------------------------------*/
.pouring-feature {
  padding-bottom: clamp(6rem, 10vw, 10rem);
}
.pouring-feature__list {
  counter-reset: feature-num;
  display: flex;
  flex-direction: column;
  row-gap: clamp(2rem, 4vw, 4rem);
}
@media (min-width: 1024px) {
  .pouring-feature__list {
    padding-top: 2em;
    row-gap: clamp(2rem, 6vw, 6rem);
  }
}

.feature-box {
  position: relative;
}
.feature-box__inner {
  position: relative;
}
@media (min-width: 768px) {
  .feature-box__inner {
    padding-top: 3rem;
  }
}
@media (min-width: 768px) {
  .feature-box:nth-of-type(odd) .feature-box__img {
    left: 0;
  }
}
@media (min-width: 768px) {
  .feature-box:nth-of-type(odd) .feature-box__txt {
    margin-left: 8.933%;
    display: flex;
    justify-content: flex-end;
  }
}
@media (min-width: 768px) {
  .feature-box:nth-of-type(even) .feature-box__img {
    right: 0;
  }
}
@media (min-width: 768px) {
  .feature-box:nth-of-type(even) .feature-box__txt {
    margin-right: 8.933%;
  }
}

.feature-box__img {
  margin: 0;
}
@media (min-width: 768px) {
  .feature-box__img {
    width: 40%;
    position: absolute;
    top: 0;
  }
}
@media (max-width: 767px) {
  .feature-box__img {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
.feature-box__img__inner {
  border-radius: clamp(8px, 1.06vw, 16px);
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
}
.feature-box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.feature-box__img__inner > div,
.feature-box__img__inner figure,
.feature-box__img__inner a,
.feature-box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.feature-box__txt {
  border-radius: clamp(8px, 1.06vw, 16px);
  background: #FDF7F7;
}
@media (min-width: 768px) {
  .feature-box__txt {
    padding: 2rem 8% 4em;
  }
}
@media (min-width: 1024px) {
  .feature-box__txt {
    min-height: 460px;
  }
}
@media (max-width: 767px) {
  .feature-box__txt {
    padding: 6rem 5% 1.5em;
    margin-top: -4.5rem;
  }
}
.feature-box__txt__inner {
  position: relative;
}
@media (min-width: 768px) {
  .feature-box__txt__inner {
    padding-top: 4rem;
    width: 58%;
    flex-shrink: 1;
  }
}
@media (max-width: 767px) {
  .feature-box__txt__inner {
    padding: 2rem 5% 0;
  }
}
.feature-box__txt__num {
  position: absolute;
  left: 5%;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  z-index: 2;
  color: #C30D23;
  display: block;
  font-size: calc(3rem + 48 * (100vw - 376px) / 1124);
}
@media (min-width: 768px) {
  .feature-box__txt__num {
    bottom: calc(100% - 1rem);
    left: 0;
  }
}
@media (min-width: 1597px) {
  .feature-box__txt__num {
    font-size: 6rem;
  }
}
@media (max-width: 767px) {
  .feature-box__txt__num {
    bottom: 100%;
  }
}
.feature-box__txt__num span {
  display: block;
  position: relative;
  line-height: 1;
}
.feature-box__txt__num span::before {
  counter-increment: feature-num;
  content: counter(feature-num, decimal-leading-zero);
}
.feature-box__txt__num span::after {
  content: "";
  position: absolute;
  left: 5%;
  bottom: -8px;
  width: 90%;
  height: 4px;
  background: #C30D23;
}
@media (min-width: 768px) {
  .feature-box__txt__num span::after {
    height: 6px;
  }
}
@media (min-width: 1024px) {
  .feature-box__txt__num span::after {
    height: 8px;
  }
}
.feature-box__txt__tit {
  margin-bottom: calc(0.5rem + 0.5em);
  font-weight: 700;
  font-size: calc(1.14rem + 14 * (100vw - 376px) / 1124);
  line-height: 1.2;
}
@media (min-width: 768px) {
  .feature-box__txt__tit {
    font-size: calc(1.14rem + 14 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .feature-box__txt__tit {
    font-size: 2rem;
  }
}
.feature-box__txt__tit b {
  color: #C30D23;
}
.feature-box__txt__txt ul {
  text-align: left;
}
.feature-box__txt__txt ul li {
  line-height: 1.4;
  padding-left: 1.5rem;
  position: relative;
}
.feature-box__txt__txt ul li::before {
  content: "●";
  color: #C30D23;
  position: absolute;
  left: 0;
  top: 0.2em;
  font-size: 0.8em;
}
.feature-box__txt__txt ul li:not(:last-child) {
  margin-bottom: 0.75em;
}

.pouring-construction {
  padding-bottom: clamp(6rem, 10vw, 10rem);
}
@media (min-width: 1024px) {
  .pouring-construction .l-col-3-sm {
    padding-top: 2em;
  }
}

.construction-box {
  position: relative;
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.construction-box__inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.construction-box__img {
  position: relative;
  margin: 0;
  position: relative;
  width: 100%;
  z-index: 1;
  z-index: 2;
  border-radius: clamp(8px, 1.06vw, 16px);
  overflow: hidden;
  width: 90%;
}
.construction-box__img::before {
  content: "";
  display: block;
  padding-top: 56.25%;
  position: relative;
}
.construction-box__img > div,
.construction-box__img figure,
.construction-box__img a,
.construction-box__img picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media (min-width: 768px) {
  .construction-box__img {
    width: 84%;
  }
}
.construction-box__txt {
  position: relative;
  z-index: 1;
  background: #EDEEF6;
  border-radius: clamp(8px, 1.06vw, 16px);
  padding: 6rem 5% 1.5em;
  margin-top: -4.5rem;
  flex-shrink: 1;
}
@media (min-width: 768px) {
  .construction-box__txt {
    padding: 6rem 8% 1.5em;
  }
}
.construction-box__txt__tit {
  font-weight: 700;
  padding-bottom: 0.75em;
  margin-bottom: calc(1em + 0.5rem);
  text-align: center;
  font-size: calc(1.14rem + 6 * (100vw - 376px) / 1124);
  position: relative;
}
@media (min-width: 576px) {
  .construction-box__txt__tit {
    font-size: calc(1.14rem + 6 * (100vw - 576px) / 924);
  }
}
@media (min-width: 1024px) {
  .construction-box__txt__tit {
    font-size: calc(1.14rem + 6 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1597px) {
  .construction-box__txt__tit {
    font-size: 1.5rem;
  }
}
.construction-box__txt__tit::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: calc(50% - 1.5rem);
  background: #C30D23;
  width: 3rem;
  height: 3px;
  border-radius: 3px;
}

.pouring-results {
  padding: clamp(4rem, 6vw, 6rem) 0 clamp(4rem, 8vw, 8rem);
  margin-bottom: clamp(6rem, 9vw, 9rem);
  position: relative;
  background: #EDEEF6;
}
.pouring-results::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: #C30D23;
  width: 100%;
  height: 30%;
}
@media (min-width: 1024px) {
  .pouring-results::before {
    min-height: 500px;
  }
}
.pouring-results__inner {
  position: relative;
  z-index: 2;
  display: flex;
}
@media (min-width: 1024px) {
  .pouring-results__inner {
    column-gap: 5%;
    justify-content: space-between;
  }
}
@media (max-width: 1023px) {
  .pouring-results__inner {
    flex-direction: column;
  }
}
.pouring-results__tit {
  margin-bottom: calc(1rem + 2em);
  font-size: calc(1.14rem + 16 * (100vw - 376px) / 1124);
}
@media (min-width: 1024px) {
  .pouring-results__tit {
    flex-basis: auto;
    flex-shrink: 0;
    font-size: calc(1.25rem + 12 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1597px) {
  .pouring-results__tit {
    font-size: 2rem;
  }
}
.pouring-results__tit em {
  display: block;
  font-style: normal;
  color: #FFFFFF;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  line-height: 1;
  letter-spacing: 0;
  font-size: calc(1.75rem + 48 * (100vw - 376px) / 1124);
  padding-bottom: 0.2em;
}
@media (min-width: 1024px) {
  .pouring-results__tit em {
    font-size: calc(2rem + 36 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1597px) {
  .pouring-results__tit em {
    font-size: 4.5rem;
  }
}
.pouring-results__tit b {
  display: block;
  color: #FFFFFF;
  font-weight: 700;
}
@media (min-width: 1024px) {
  .pouring-results__txt {
    flex-basis: 65.33%;
    flex-shrink: 1;
  }
}
.pouring-results__txt__inner {
  padding: clamp(1.5em, 3vw, 3em) 5%;
  background: #FFFFFF;
  border-radius: clamp(8px, 1.06vw, 16px);
}
@media (min-width: 768px) {
  .pouring-results__txt__inner {
    padding: clamp(1.5em, 3vw, 3em) 8%;
  }
}

/*--------------------------------------------------------------------/
	sales
/--------------------------------------------------------------------*/
.sales-feature {
  padding-bottom: clamp(6rem, 10vw, 10rem);
}
.sales-feature__list {
  counter-reset: feature-num;
  display: flex;
  flex-direction: column;
  row-gap: clamp(2rem, 4vw, 4rem);
}
@media (min-width: 1024px) {
  .sales-feature__list {
    padding-top: 2em;
    row-gap: clamp(2rem, 6vw, 6rem);
  }
}

.sales-flow {
  padding: 0 0 clamp(4rem, 8vw, 8rem);
  margin-bottom: clamp(6rem, 9vw, 9rem);
  background: #FDF7F7;
}
.sales-flow__inner {
  position: relative;
  padding-top: clamp(2rem, 4vw, 4rem);
}
.sales-flow__add {
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(100% - 1em);
  line-height: 1;
  font-size: calc(1.14rem + 14 * (100vw - 376px) / 1124);
  text-align: center;
}
@media (min-width: 1597px) {
  .sales-flow__add {
    font-size: 2rem;
  }
}
.sales-flow__add span {
  display: inline-block;
  background: #C30D23;
  color: #FFFFFF;
  padding: 0.5em 1.5em;
  border-radius: 999px;
  font-weight: 700;
}
.sales-flow__tit {
  font-size: calc(1.25rem + 24 * (100vw - 280px) / 1220);
  text-align: center;
  font-weight: 700;
  margin-bottom: calc(1rem + 1em);
}
@media (min-width: 1597px) {
  .sales-flow__tit {
    font-size: 3rem;
  }
}
.sales-flow__list {
  display: flex;
  counter-reset: flow-num;
}
@media (min-width: 1597px) {
  .sales-flow__list {
    padding-top: 2em;
    justify-content: space-between;
  }
}
@media (max-width: 1596px) {
  .sales-flow__list {
    flex-direction: column;
    row-gap: 3rem;
  }
}

.flow-box {
  position: relative;
  z-index: 2;
}
@media (min-width: 1597px) {
  .flow-box {
    width: 21.38%;
  }
}
.flow-box:not(:last-child)::after {
  position: absolute;
  content: "";
  background-color: transparent;
  background-image: radial-gradient(#FF8594 30%, transparent 30%);
  background-size: 16px 16px;
}
@media (min-width: 1597px) {
  .flow-box:not(:last-child)::after {
    left: 100%;
    top: calc(50% - 8px);
    width: 50%;
    height: 16px;
  }
}
@media (max-width: 1596px) {
  .flow-box:not(:last-child)::after {
    top: 100%;
    left: 2%;
    height: 50%;
    width: 16px;
  }
}
@media (max-width: 575px) {
  .flow-box:not(:last-child)::after {
    left: calc(50% - 8px);
  }
}
.flow-box__inner {
  position: relative;
  border: 1px solid #C30D23;
  border-radius: clamp(8px, 1.06vw, 16px);
  background: #FFFFFF;
  height: 100%;
  padding: 2em 5% 1.5em;
}
@media (min-width: 576px) and (max-width: 1596px) {
  .flow-box__inner {
    padding-top: 3em;
  }
}
@media (min-width: 1597px) {
  .flow-box__inner {
    padding: 2.5em 5% 1.5em;
  }
}
@media (min-width: 1740px) {
  .flow-box__inner {
    padding: 2.5em 8% 1.5em;
  }
}
@media (min-width: 576px) and (max-width: 1596px) {
  .flow-box__inner {
    display: grid;
    grid-template-columns: 30% 62%;
    grid-template-rows: auto 1fr;
    column-gap: 8%;
  }
}

.flow-box__num {
  position: absolute;
  bottom: calc(100% - 1.5em);
  left: 5%;
  line-height: 1;
  font-size: 0.8em;
}
@media (min-width: 576px) and (max-width: 1596px) {
  .flow-box__num {
    left: 5%;
  }
}
@media (min-width: 1597px) {
  .flow-box__num {
    left: 5%;
    font-size: 1em;
  }
}
@media (min-width: 1740px) {
  .flow-box__num {
    left: 8%;
  }
}
.flow-box__num span {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  background: #C30D23;
  color: #FFFFFF;
  display: inline-block;
  padding: 0.5em 1.5em;
  border-radius: 999px;
}
.flow-box__num span::after {
  margin-left: 0.2em;
  counter-increment: flow-num;
  content: counter(flow-num, decimal-leading-zero);
  font-size: 2em;
}

.flow-box__tit {
  font-weight: 700;
  margin-bottom: calc(0.5rem + 0.5em);
  font-size: calc(1.14rem + 6 * (100vw - 280px) / 1220);
  text-align: center;
}
@media (min-width: 576px) and (max-width: 1596px) {
  .flow-box__tit {
    grid-column: 1/3;
    row-gap: 1/2;
    font-size: calc(1.25rem + 10 * (100vw - 576px) / 924);
    padding-left: 0.3em;
    text-align: left;
  }
}
@media (min-width: 1597px) {
  .flow-box__tit {
    font-size: calc(1.14rem + 4 * (100vw - 1500px) / 160);
  }
}
@media (min-width: 1740px) {
  .flow-box__tit {
    font-size: 1.38rem;
  }
}
.flow-box__img {
  margin-bottom: 1em;
}
.flow-box__img__inner {
  margin: 0;
  position: relative;
  width: 100%;
  z-index: 1;
}
.flow-box__img__inner::before {
  content: "";
  display: block;
  padding-top: 50%;
  position: relative;
}
.flow-box__img__inner > div,
.flow-box__img__inner figure,
.flow-box__img__inner a,
.flow-box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/*--------------------------------------------------------------------/
	pumpcarlist
/--------------------------------------------------------------------*/
.pumpcarlist-item {
  padding-bottom: clamp(6rem, 12vw, 12rem);
}
.pumpcarlist-item__list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(4rem, 14vw, 14rem);
}

.pumpcarlist-item__box {
  position: relative;
  padding-bottom: clamp(3rem, 4vw, 4rem);
}
@media (min-width: 1597px) {
  .pumpcarlist-item__box {
    padding-bottom: clamp(3rem, 8vw, 8rem);
  }
}
.pumpcarlist-item__box::before {
  position: absolute;
  bottom: 0;
  right: 0;
  content: "";
  background: #FDF7F7;
  width: 98%;
  height: 70%;
  border-top-left-radius: clamp(8px, 1.06vw, 16px);
  border-bottom-left-radius: clamp(8px, 1.06vw, 16px);
}
@media (min-width: 1597px) {
  .pumpcarlist-item__box::before {
    max-width: calc(50% + 830px);
  }
}
.pumpcarlist-item__box__inner {
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .pumpcarlist-item__box__inner {
    display: grid;
    grid-template-columns: 54% 40%;
    grid-template-rows: auto 1fr;
    column-gap: 6%;
  }
}
@media (min-width: 1024px) {
  .pumpcarlist-item__box__inner {
    grid-template-columns: 48% 40%;
    column-gap: 12%;
  }
}
.pumpcarlist-item__box__tit {
  font-weight: 700;
  margin-bottom: calc(0.5rem + 0.75em);
  font-size: calc(0.94rem + 14 * (100vw - 280px) / 1220);
  position: relative;
  padding-top: 2em;
}
@media (min-width: 768px) {
  .pumpcarlist-item__box__tit {
    grid-column: 1/2;
    grid-row: 1/2;
    font-size: calc(1.14rem + 14 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .pumpcarlist-item__box__tit {
    font-size: 2rem;
  }
}
.pumpcarlist-item__box__tit::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 1px;
  background: #C30D23;
}
@media (min-width: 768px) {
  .pumpcarlist-item__box__img {
    grid-column: 2/3;
    grid-row: 1/3;
  }
}
.pumpcarlist-item__box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  border-radius: clamp(8px, 1.06vw, 16px);
  overflow: hidden;
}
.pumpcarlist-item__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.pumpcarlist-item__box__img__inner > div,
.pumpcarlist-item__box__img__inner figure,
.pumpcarlist-item__box__img__inner a,
.pumpcarlist-item__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media (min-width: 768px) {
  .pumpcarlist-item__box__txt {
    grid-column: 1/2;
    grid-row: 2/3;
    max-width: 95%;
  }
}

.pumpcarlist-pumpcar .txt {
  font-weight: 500;
}
.pumpcarlist-pumpcar .pagination {
  padding-top: clamp(3rem, 5vw, 5rem);
}
.pumpcarlist-pumpcar__list {
  display: flex;
  margin-top: clamp(2rem, 3vw, 3rem);
}
@media (min-width: 768px) {
  .pumpcarlist-pumpcar__list {
    column-gap: 2%;
    row-gap: clamp(2rem, 3vw, 3rem);
    flex-wrap: wrap;
  }
  .pumpcarlist-pumpcar__list > * {
    width: 49%;
  }
}
@media (max-width: 767px) {
  .pumpcarlist-pumpcar__list {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}

.pumpcar-box {
  padding-top: clamp(1.5rem, 2vw, 2rem);
}
@media (min-width: 768px) {
  .pumpcar-box {
    padding-top: clamp(2rem, 3vw, 3rem);
  }
}
.pumpcar-box__inner {
  background: #FFFFFF;
  border-radius: clamp(8px, 1.06vw, 16px);
  border: 1px solid #d46c79;
  padding: 1.5em 5% 2em;
  position: relative;
}
@media (min-width: 768px) {
  .pumpcar-box__inner {
    padding: 2em 3% 2em;
  }
}
.pumpcar-box__add {
  position: absolute;
  left: 5%;
  line-height: 1;
  bottom: calc(100% - 0.75em);
  color: #C30D23;
  z-index: 3;
  font-size: calc(1.25rem + 12 * (100vw - 280px) / 1220);
  background: #FFFFFF;
  display: inline-block;
  padding: 0.2em 0.5em;
}
@media (min-width: 768px) {
  .pumpcar-box__add {
    font-size: calc(1.25rem + 12 * (100vw - 768px) / 732);
    left: 3%;
  }
}
@media (min-width: 1597px) {
  .pumpcar-box__add {
    font-size: 2rem;
  }
}
.pumpcar-box__add b {
  font-weight: 700;
  font-size: 1.6em;
  font-family: 'Roboto', sans-serif;
}
.pumpcar-box__add span {
  font-weight: 700;
  font-family: 'Noto Sans JP', sans-serif;
}
.pumpcar-box__tit {
  margin-bottom: calc(1rem + 0.5em);
  font-weight: 700;
  text-align: center;
  line-height: 1.2;
}
.pumpcar-box__tit b {
  display: block;
  margin-bottom: 0.2em;
}
@media (min-width: 768px) {
  .pumpcar-box__tit b {
    font-size: calc(0.94rem + 6 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .pumpcar-box__tit b {
    font-size: 1.5rem;
  }
}
.pumpcar-box__tit em {
  font-style: normal;
  display: block;
}
.pumpcar-box__intro {
  display: flex;
  margin-bottom: clamp(1.5rem, 2vw, 2rem);
}
@media (min-width: 376px) {
  .pumpcar-box__intro {
    justify-content: center;
    column-gap: 6%;
  }
}
@media (max-width: 375px) {
  .pumpcar-box__intro {
    flex-direction: column;
    row-gap: 1em;
  }
}
.pumpcar-box__img {
  margin: 0;
  width: 100%;
}
@media (min-width: 376px) {
  .pumpcar-box__img {
    width: 54%;
  }
}
.pumpcar-box__img__inner {
  border-radius: clamp(8px, 1.06vw, 16px);
  overflow: hidden;
}
.pumpcar-box__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
}
@media (min-width: 376px) {
  .pumpcar-box__list {
    width: 40%;
  }
}
@media (min-width: 376px) {
  .pumpcar-box__list a {
    font-size: 0.9em;
  }
}
.pumpcar-box__catalog a {
  font-weight: 500;
  display: block;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  padding: 0.75em 2%;
  width: 100%;
  border-radius: 999px;
  border: 1px solid #4B4D4E;
  background: #E2E6E9;
  text-align: center;
  color: #4B4D4E;
  min-height: 44px;
}
.pumpcar-box__catalog a span {
  vertical-align: top;
}
.pumpcar-box__catalog a span:before {
  content: "\e904";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pumpcar-box__catalog a span::before {
  font-size: 1.5em;
  margin-right: 0.2em;
  transition: all 0.3s ease 0s;
}
.pumpcar-box__catalog a:hover {
  background: #292D2E;
  color: #D5DEE6;
  border-color: #D5DEE6;
}
.pumpcar-box__catalog a:hover span::before {
  color: #D5DEE6;
}
.pumpcar-box__link a {
  font-weight: 500;
  display: block;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  padding: 0.75em 2%;
  width: 100%;
  border-radius: 999px;
  border: 1px solid #C30D23;
  background: #C30D23;
  text-align: center;
  color: #FFFFFF;
  min-height: 44px;
}
.pumpcar-box__link a:hover {
  background: #FFFFFF;
  color: #C30D23;
  border-color: #C30D23;
}

.pumpcar-box__detail__table {
  width: 100%;
  border-radius: clamp(4px, 0.53vw, 8px);
  border-collapse: separate;
  overflow: hidden;
  border: 1px solid #d46c79;
  line-height: 1.5;
}
.pumpcar-box__detail__table tr:last-child th,
.pumpcar-box__detail__table tr:last-child td {
  border-bottom: none;
}
.pumpcar-box__detail__table th {
  vertical-align: middle;
  text-align: left;
  padding: 0.5em 0.75em;
  border-bottom: 1px solid #d46c79;
  border-right: 1px solid #d46c79;
  background: #FFD9DE;
  width: 30%;
  font-size: 0.84em;
}
@media (max-width: 575px) {
  .pumpcar-box__detail__table th {
    width: 35%;
    font-size: 0.8em;
  }
}
.pumpcar-box__detail__table th.border-none {
  border-bottom: none;
}
.pumpcar-box__detail__table td {
  padding: 0.5em 0.75em;
  border-bottom: 1px solid #d46c79;
  font-size: 0.84em;
}
@media (max-width: 575px) {
  .pumpcar-box__detail__table td {
    font-size: 0.8em;
  }
}
.pumpcar-box__detail__table td.is-common {
  background: #FFFFFF;
  width: auto;
}
.pumpcar-box__detail__table td.is-sub {
  background: #FDF7F7;
  width: 30%;
}

/*--------------------------------------------------------------------/
	recruit
/--------------------------------------------------------------------*/
.recruit-tit {
  text-align: center;
  line-height: 1.4;
  padding: 1em 4%;
  border-radius: clamp(8px, 1.06vw, 16px);
  margin-bottom: calc(2rem + 1.5em);
  position: relative;
  font-weight: 700;
  font-size: calc(1.14rem + 20 * (100vw - 376px) / 1124);
  background: #C30D23;
  color: #FFFFFF;
}
@media (min-width: 1597px) {
  .recruit-tit {
    font-size: 2.38rem;
  }
}

.recruit-navi {
  margin-bottom: clamp(3rem, 9vw, 9rem);
}
@media (min-width: 768px) {
  .recruit-navi__inner {
    border-radius: 999px;
    padding: 2em 1.5em;
    border: 1px solid #C30D23;
  }
}
.recruit-navi__inner ul {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  row-gap: 1em;
}
@media (min-width: 768px) {
  .recruit-navi__inner ul {
    column-gap: 0.2em;
  }
}
@media (max-width: 767px) {
  .recruit-navi__inner ul {
    flex-direction: column;
    padding: 1em;
  }
}
.recruit-navi__inner ul li {
  line-height: 1;
}
.recruit-navi__inner ul li a {
  text-decoration: none !important;
  font-weight: 700;
  padding-right: 1.75em;
  position: relative;
  transition: all 0.3s ease 0s;
  font-size: calc(1rem + 4 * (100vw - 280px) / 1220);
}
.recruit-navi__inner ul li a:after {
  content: "\e313";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (min-width: 768px) {
  .recruit-navi__inner ul li a {
    font-size: calc(0.82rem + 5 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .recruit-navi__inner ul li a {
    font-size: 1.21em;
  }
}
@media (min-width: 1597px) and (min-width: 576px) {
  .recruit-navi__inner ul li a {
    font-size: 1.13em;
  }
}
@media (min-width: 1597px) and (min-width: 1024px) {
  .recruit-navi__inner ul li a {
    font-size: 1.06em;
  }
}
@media (max-width: 767px) {
  .recruit-navi__inner ul li a {
    padding: 1em 3em 1em 1.75em;
    background: #C30D23;
    color: #FFFFFF;
    display: block;
    border-radius: 999px;
  }
}
.recruit-navi__inner ul li a::after {
  position: absolute;
  font-size: 1.42em;
  transition: all 0.3s ease 0s;
}
@media (min-width: 576px) {
  .recruit-navi__inner ul li a::after {
    font-size: 1.33em;
  }
}
@media (min-width: 1024px) {
  .recruit-navi__inner ul li a::after {
    font-size: 1.25em;
  }
}
@media (min-width: 768px) {
  .recruit-navi__inner ul li a::after {
    right: 0;
    top: 0.2em;
  }
}
@media (max-width: 767px) {
  .recruit-navi__inner ul li a::after {
    top: 0.6em;
    right: 1em;
  }
}
@media (min-width: 768px) {
  .recruit-navi__inner ul li a:hover::after {
    color: #e3021d;
    top: 0.4em;
  }
}
@media (max-width: 767px) {
  .recruit-navi__inner ul li a:hover::after {
    background: #e3021d;
    top: 0.8em;
  }
}

.recruit-message {
  padding-bottom: clamp(9rem, 14vw, 14rem);
}

.recruit-message__intro {
  padding-bottom: clamp(5rem, 8vw, 8rem);
  position: relative;
}
@media (min-width: 768px) {
  .recruit-message__intro {
    padding-bottom: clamp(8rem, 10vw, 11rem);
  }
}
.recruit-message__intro::before {
  content: "";
  background: #C30D23;
  position: absolute;
  height: calc(100% - 5rem);
  right: 0;
  bottom: 0;
  transform-origin: top right;
  z-index: 1;
  border-top-left-radius: clamp(64px, 8.533vw, 128px);
  border-bottom-left-radius: clamp(64px, 8.533vw, 128px);
  width: 95%;
}
@media (min-width: 768px) {
  .recruit-message__intro::before {
    height: 60%;
    width: 92%;
  }
}
@media (min-width: 1597px) {
  .recruit-message__intro::before {
    width: calc(50% + 590px);
  }
}
.recruit-message__intro__inner {
  position: relative;
  z-index: 2;
  background: #FFFFFF;
  padding-top: clamp(3rem, 6vw, 6rem);
  padding-bottom: clamp(3rem, 8vw, 8rem);
  border-bottom-right-radius: clamp(64px, 8.533vw, 128px);
  width: 95%;
  padding-left: 5%;
}
@media (min-width: 768px) {
  .recruit-message__intro__inner {
    display: flex;
    justify-content: flex-end;
    width: 92%;
    padding-left: 8%;
    padding-bottom: clamp(3rem, 11vw, 11rem);
  }
}
@media (min-width: 1597px) {
  .recruit-message__intro__inner {
    min-height: 500px;
    width: calc(50% + 590px);
  }
}
@media (max-width: 767px) {
  .recruit-message__intro__inner {
    padding-right: 5%;
  }
}
.recruit-message__intro__inner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #C30D23;
  width: 80%;
  height: 1px;
}
@media (min-width: 768px) {
  .recruit-message__intro__inner::before {
    width: 48.4375vw;
  }
}
.recruit-message__intro__inner__inner {
  width: 100%;
  max-width: 1280px;
  position: relative;
}
@media (max-width: 767px) {
  .recruit-message__intro__inner__inner {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 1em;
  }
}

@media (min-width: 768px) {
  .recruit-message__txt {
    width: 48.4375%;
    line-height: 2;
  }
}
@media (min-width: 1597px) {
  .recruit-message__txt {
    font-size: 1.06rem;
  }
}

.recruit-message__tit {
  font-weight: 700;
  font-size: calc(1.5rem + 12 * (100vw - 376px) / 1124);
  margin-bottom: calc(1rem + 0.75em);
}
@media (min-width: 768px) {
  .recruit-message__tit {
    font-size: calc(1.5rem + 12 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .recruit-message__tit {
    font-size: 2.25rem;
  }
}

@media (min-width: 768px) {
  .recruit-message__img {
    position: absolute;
    top: 4vw;
    right: -5%;
    width: 50%;
  }
}
@media (min-width: 1597px) {
  .recruit-message__img {
    top: 2rem;
    right: -12.5%;
    max-width: 700px;
  }
}
@media (max-width: 767px) {
  .recruit-message__img {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
.recruit-message__img__inner {
  border-radius: clamp(8px, 1.06vw, 16px);
  position: relative;
  overflow: hidden;
  margin: 0;
  z-index: 1;
}
.recruit-message__img__inner:after {
  content: "";
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.recruit-message__img__inner::after {
  transition: all 0.3s ease 0s;
}
.recruit-message__img__inner:hover::after {
  background: rgba(0, 0, 0, 0.5);
}
.recruit-message__img i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
  font-size: calc(3rem + 32 * (100vw - 376px) / 1124);
  z-index: 3;
}
@media (min-width: 768px) {
  .recruit-message__img i {
    font-size: calc(3rem + 32 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .recruit-message__img i {
    font-size: 5rem;
  }
}

.recruit-message__sub {
  margin-top: clamp(4rem, 6vw, 6rem);
  margin-bottom: clamp(4rem, 6vw, 6rem);
  border-radius: clamp(8px, 1.06vw, 16px);
  border: 1px solid #C30D23;
  padding: 1.5em 5% 2em;
}
@media (min-width: 1024px) {
  .recruit-message__sub {
    padding: 2.5em 4% 3em;
  }
}
.recruit-message__sub__inner {
  display: flex;
}
@media (min-width: 1024px) {
  .recruit-message__sub__inner {
    justify-content: space-between;
  }
}
@media (max-width: 1023px) {
  .recruit-message__sub__inner {
    flex-direction: column;
    row-gap: clamp(1.5rem, 2vw, 2rem);
  }
}
.recruit-message__sub__tit {
  font-size: calc(1.14rem + 14 * (100vw - 280px) / 1220);
  font-weight: 700;
}
@media (min-width: 1024px) {
  .recruit-message__sub__tit {
    flex-basis: 30%;
    flex-shrink: 0;
    font-size: calc(1.14rem + 8 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1597px) {
  .recruit-message__sub__tit {
    font-size: 1.75rem;
  }
}
@media (max-width: 1023px) {
  .recruit-message__sub__tit {
    text-align: center;
  }
}
.recruit-message__sub__tit span {
  display: inline-block;
  position: relative;
  padding-bottom: 1em;
}
.recruit-message__sub__tit span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: #C30D23;
  width: 100%;
  height: 2px;
}
@media (min-width: 1024px) {
  .recruit-message__sub__txt {
    flex-basis: 64%;
    flex-shrink: 0;
  }
}

.recruit-message__promise__tit {
  font-size: calc(1.25rem + 12 * (100vw - 280px) / 1220);
  font-weight: 700;
  text-align: center;
  margin-bottom: calc(2em + 1.5rem);
}
@media (min-width: 1597px) {
  .recruit-message__promise__tit {
    font-size: 2rem;
  }
}
.recruit-message__promise__tit span {
  color: #C30D23;
}
.recruit-message__promise__tit b {
  font-size: 1.5em;
}
.recruit-message__promise__list {
  display: flex;
}
@media (min-width: 1597px) {
  .recruit-message__promise__list {
    justify-content: space-between;
  }
}
@media (max-width: 1596px) {
  .recruit-message__promise__list {
    flex-direction: column;
    row-gap: clamp(4rem, 6vw, 6rem);
  }
}

@media (min-width: 1597px) {
  .promise-box {
    width: 30.9%;
  }
}
.promise-box__inner {
  background: #FDF7F7;
  border-radius: clamp(8px, 1.06vw, 16px);
  padding: 3.5em 5% 2em;
  position: relative;
}
@media (min-width: 768px) {
  .promise-box__inner {
    padding: 4em 8% 2em;
  }
}
.promise-box__num {
  position: absolute;
  bottom: calc(100% - 0.4em);
  color: #C30D23;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0;
  line-height: 1;
  font-size: calc(2.5rem + 20 * (100vw - 280px) / 1220);
}
@media (min-width: 1597px) {
  .promise-box__num {
    font-size: 3.75rem;
  }
}
.promise-box__num span {
  position: relative;
  padding-bottom: 0.1em;
}
.promise-box__num span::after {
  content: "";
  position: absolute;
  left: 2.5%;
  bottom: -3px;
  height: 4px;
  width: 95%;
  background: #C30D23;
}
@media (min-width: 768px) {
  .promise-box__num span::after {
    height: 6px;
  }
}
.promise-box__tit {
  font-weight: 700;
  margin-bottom: calc(0.5rem + 0.5em);
  font-size: calc(1rem + 8 * (100vw - 280px) / 1220);
}
@media (min-width: 1597px) {
  .promise-box__tit {
    font-size: calc(1.14rem + 2 * (100vw - 1500px) / 160);
  }
}
@media (min-width: 1740px) {
  .promise-box__tit {
    font-size: 1.25rem;
  }
}

.recruit-environment {
  padding-bottom: clamp(9rem, 14vw, 14rem);
  position: relative;
}
.recruit-environment::before {
  position: absolute;
  width: 100%;
  height: 10rem;
  left: 0;
  top: calc(1.5rem + 1em + 64px);
  content: "";
  background: #FDF7F7;
}
@media (min-width: 1597px) {
  .recruit-environment::before {
    top: calc(9rem + 1em);
  }
}
.recruit-environment__inner {
  position: relative;
  z-index: 1;
}
.recruit-environment__list {
  background: #FDF7F7;
  position: relative;
  padding-bottom: clamp(3rem, 9vw, 9rem);
}
.recruit-environment__list__inner {
  display: flex;
}
@media (min-width: 768px) {
  .recruit-environment__list__inner {
    flex-wrap: wrap;
    column-gap: 3.62%;
    row-gap: clamp(3rem, 6vw, 6rem);
  }
}
@media (max-width: 767px) {
  .recruit-environment__list__inner {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
@media (min-width: 768px) {
  .recruit-environment__list__inner > * {
    width: 48.19%;
  }
}

.environment-box__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.environment-box__tit {
  background: #C30D23;
  color: #FFFFFF;
  padding: 1em 5%;
  border-top-left-radius: clamp(8px, 1.06vw, 16px);
  border-top-right-radius: clamp(8px, 1.06vw, 16px);
}
@media (min-width: 768px) {
  .environment-box__tit {
    font-size: calc(1rem + 3 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .environment-box__tit {
    font-size: 1.25rem;
  }
}
.environment-box__txt {
  padding: 1.5em 5% 2em;
  border-bottom-left-radius: clamp(8px, 1.06vw, 16px);
  border-bottom-right-radius: clamp(8px, 1.06vw, 16px);
  background: #FFFFFF;
  flex-shrink: 1;
  height: 100%;
}
.environment-box__txt__flex {
  display: flex;
}
@media (min-width: 576px) {
  .environment-box__txt__flex {
    justify-content: space-between;
  }
}
@media (min-width: 768px) and (max-width: 1596px) {
  .environment-box__txt__flex {
    flex-direction: column;
    row-gap: 1em;
  }
}
@media (max-width: 575px) {
  .environment-box__txt__flex {
    flex-direction: column;
    row-gap: 1em;
  }
}
.environment-box__txt__img {
  margin: 0;
}
@media (min-width: 576px) {
  .environment-box__txt__img {
    width: 42%;
  }
}
@media (min-width: 768px) and (max-width: 1596px) {
  .environment-box__txt__img {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .environment-box__txt__img {
    width: 100%;
  }
}
.environment-box__txt__img__inner {
  border-radius: clamp(8px, 1.06vw, 16px);
  overflow: hidden;
}
@media (min-width: 576px) {
  .environment-box__txt__txt {
    width: 54%;
  }
}
@media (min-width: 768px) and (max-width: 1596px) {
  .environment-box__txt__txt {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .environment-box__txt__txt {
    width: 100%;
  }
}

.recruit-environment__hinode {
  padding-top: clamp(3rem, 6vw, 6rem);
}
.recruit-environment__hinode__tit {
  position: relative;
  font-size: calc(1.14rem + 16 * (100vw - 376px) / 1124);
  font-weight: 700;
  margin-bottom: calc(1em + 1rem);
  text-align: center;
}
@media (min-width: 1597px) {
  .recruit-environment__hinode__tit {
    font-size: 2.25rem;
  }
}
.recruit-environment__hinode__tit span {
  position: relative;
  display: inline-block;
  padding-bottom: 0.75em;
}
.recruit-environment__hinode__tit span::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20%;
  background: #C30D23;
  width: 60%;
  height: 4px;
  border-radius: 3px;
}
.recruit-environment__hinode__intro {
  margin-bottom: clamp(3rem, 6vw, 6rem);
}
@media (min-width: 768px) {
  .recruit-environment__hinode__intro {
    line-height: 2;
    font-weight: 500;
  }
}
@media (min-width: 1024px) {
  .recruit-environment__hinode__intro {
    font-size: 1.13rem;
  }
}

.recruit-environment__hinode__sub {
  padding: 2em 5% 2em;
  border-radius: clamp(8px, 1.06vw, 16px);
  border: 1px solid;
  background: #FFFFFF;
}
@media (min-width: 1024px) {
  .recruit-environment__hinode__sub {
    padding: 3em 5% 3.5em;
  }
}
.recruit-environment__hinode__sub.is-hinode {
  border-color: #5d9e36;
}
.recruit-environment__hinode__sub.is-hinode .recruit-environment__hinode__sub__tit {
  color: #5d9e36;
}
.recruit-environment__hinode__sub.is-hinode .recruit-environment__hinode__sub__list {
  counter-reset: hinode-num;
}
.recruit-environment__hinode__sub.is-hinode .recruit-environment__hinode__sub__item::before {
  counter-increment: hinode-num;
  content: counter(hinode-num, decimal-leading-zero);
  color: #5d9e36;
}
.recruit-environment__hinode__sub.is-tokyo {
  margin-top: clamp(2rem, 4vw, 4rem);
  border-color: #002063;
}
.recruit-environment__hinode__sub.is-tokyo .recruit-environment__hinode__sub__tit {
  color: #002063;
}
.recruit-environment__hinode__sub.is-tokyo .recruit-environment__hinode__sub__list {
  counter-reset: tokyo-num;
}
.recruit-environment__hinode__sub.is-tokyo .recruit-environment__hinode__sub__item::before {
  counter-increment: tokyo-num;
  content: counter(tokyo-num, decimal-leading-zero);
  color: #002063;
}
.recruit-environment__hinode__sub__tit {
  text-align: center;
  font-size: calc(1rem + 8 * (100vw - 280px) / 1220);
  font-weight: 700;
  margin-bottom: calc(1rem + 0.5em);
}
@media (min-width: 1597px) {
  .recruit-environment__hinode__sub__tit {
    font-size: 1.5rem;
  }
}
.recruit-environment__hinode__sub__list {
  display: flex;
  justify-content: center;
}
.recruit-environment__hinode__sub__items {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}
.recruit-environment__hinode__sub__item {
  padding-left: 2em;
  position: relative;
  font-weight: 500;
  font-size: calc(1rem + 4 * (100vw - 280px) / 1220);
}
@media (min-width: 1024px) {
  .recruit-environment__hinode__sub__item {
    padding-left: 2.5em;
  }
}
@media (min-width: 1597px) {
  .recruit-environment__hinode__sub__item {
    font-size: 1.25rem;
  }
}
.recruit-environment__hinode__sub__item::before {
  position: absolute;
  top: 0.3em;
  left: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 0.9;
  font-size: 1.2em;
}
@media (min-width: 1024px) {
  .recruit-environment__hinode__sub__item::before {
    top: 0.2em;
    font-size: 1.5em;
  }
}

.recruit-environment__hinode__banner {
  margin-top: clamp(2rem, 3vw, 3rem);
  position: relative;
  padding: 1.5em 5%;
  border-radius: clamp(8px, 1.06vw, 16px);
  position: relative;
}
@media (min-width: 1024px) {
  .recruit-environment__hinode__banner {
    padding: 1.5em 8%;
  }
}
.recruit-environment__hinode__banner.is-hinode {
  background: #ECFFEC;
}
.recruit-environment__hinode__banner.is-hinode .recruit-environment__hinode__banner__img a {
  border-color: #5d9e36;
}
.recruit-environment__hinode__banner.is-tokyo {
  background: #ebedf2;
}
.recruit-environment__hinode__banner.is-tokyo .recruit-environment__hinode__banner__img a {
  border-color: #002063;
}
.recruit-environment__hinode__banner__add {
  position: absolute;
  width: clamp(60px, 18.66vw, 120px);
}
@media (min-width: 1024px) {
  .recruit-environment__hinode__banner__add {
    right: 0;
    top: -2rem;
  }
}
@media (min-width: 1597px) {
  .recruit-environment__hinode__banner__add {
    width: 140px;
  }
}
@media (max-width: 1023px) {
  .recruit-environment__hinode__banner__add {
    left: calc(100% - 12vw);
    bottom: -2rem;
  }
}
.recruit-environment__hinode__banner__inner {
  display: flex;
}
@media (min-width: 1024px) {
  .recruit-environment__hinode__banner__inner {
    justify-content: center;
    align-items: center;
    column-gap: 5%;
  }
}
@media (max-width: 1023px) {
  .recruit-environment__hinode__banner__inner {
    flex-direction: column;
    row-gap: 1em;
  }
}
@media (min-width: 1024px) {
  .recruit-environment__hinode__banner__txt {
    max-width: 50%;
    width: auto;
  }
}
.recruit-environment__hinode__banner__img {
  max-width: 400px;
}
@media (min-width: 1024px) {
  .recruit-environment__hinode__banner__img {
    flex-basis: auto;
  }
}
@media (max-width: 1023px) {
  .recruit-environment__hinode__banner__img {
    margin-left: auto;
    margin-right: auto;
  }
}
.recruit-environment__hinode__banner__img a {
  border-radius: clamp(4px, 0.53vw, 8px);
  overflow: hidden;
  display: block;
  border: 1px solid;
}

.recruit-voice {
  padding-bottom: clamp(9rem, 14vw, 14rem);
}
.recruit-voice__list {
  display: flex;
}
@media (min-width: 768px) {
  .recruit-voice__list {
    flex-wrap: wrap;
    column-gap: 3.62%;
    row-gap: clamp(3rem, 9vw, 9rem);
  }
}
@media (max-width: 767px) {
  .recruit-voice__list {
    flex-direction: column;
    row-gap: clamp(5rem, 9vw, 9rem);
  }
}
@media (min-width: 768px) {
  .recruit-voice__list > * {
    width: 48.19%;
  }
}

.voice-box {
  border-top: 1px solid #C30D23;
}
.voice-box__inner {
  position: relative;
  padding-top: clamp(1rem, 1.5vw, 1.5rem);
}
.voice-box__tit {
  padding-bottom: clamp(1rem, 2vw, 2rem);
}
.voice-box__tit b {
  padding-right: 2em;
  font-weight: 700;
  line-height: 1.2;
  font-size: calc(1.75rem + 16 * (100vw - 280px) / 1220);
}
@media (min-width: 768px) {
  .voice-box__tit b {
    font-size: calc(1.5rem + 8 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .voice-box__tit b {
    font-size: 2.13rem;
  }
}
@media (max-width: 1023px) {
  .voice-box__tit b {
    display: block;
    margin-bottom: 0.2em;
  }
}
.voice-box__tit span {
  display: inline-block;
}
.voice-box__flex {
  display: flex;
}
@media (min-width: 576px) {
  .voice-box__flex {
    justify-content: space-between;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .voice-box__flex {
    flex-direction: column;
    row-gap: 1em;
  }
}
@media (min-width: 1024px) and (max-width: 1596px) {
  .voice-box__flex {
    display: block;
  }
}
@media (max-width: 575px) {
  .voice-box__flex {
    flex-direction: column;
    row-gap: 1em;
  }
}
.voice-box__img {
  margin: 0;
  width: 100%;
}
@media (min-width: 576px) {
  .voice-box__img {
    width: 48.75%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .voice-box__img {
    width: 100%;
  }
}
@media (min-width: 1024px) and (max-width: 1596px) {
  .voice-box__img {
    float: left;
    margin-right: 1em;
    margin-bottom: 1em;
  }
}
.voice-box__img__inner {
  border-radius: clamp(8px, 1.06vw, 16px);
  overflow: hidden;
}
@media (min-width: 576px) {
  .voice-box__txt {
    width: 45%;
  }
}
@media (min-width: 768px) and (max-width: 1596px) {
  .voice-box__txt {
    width: 100%;
  }
}

.recruit-application {
  padding-bottom: clamp(9rem, 14vw, 14rem);
}
.recruit-application__txt {
  max-width: 1180px;
  margin: 0 auto;
}

/*--------------------------------------------------------------------/
	company
/--------------------------------------------------------------------*/
/* company-message */
.company-message {
  padding-bottom: clamp(5rem, 8vw, 8rem);
  position: relative;
}
@media (min-width: 768px) {
  .company-message {
    padding-bottom: clamp(8rem, 10vw, 11rem);
  }
}
.company-message.is-chairman {
  margin-bottom: clamp(6rem, 14vw, 14rem);
}
.company-message.is-president {
  margin-bottom: clamp(8rem, 18vw, 20rem);
}
.company-message::before {
  content: "";
  background: #C30D23;
  position: absolute;
  height: calc(100% - 5rem);
  right: 0;
  bottom: 0;
  transform-origin: top right;
  z-index: 1;
  border-top-left-radius: clamp(64px, 8.533vw, 128px);
  border-bottom-left-radius: clamp(64px, 8.533vw, 128px);
  width: 95%;
}
@media (min-width: 768px) {
  .company-message::before {
    height: 60%;
    width: 92%;
  }
}
@media (min-width: 1597px) {
  .company-message::before {
    width: calc(50% + 590px);
  }
}

.company-message__inner {
  position: relative;
  z-index: 2;
  background: #FFFFFF;
  padding-top: clamp(3rem, 6vw, 6rem);
  padding-bottom: clamp(3rem, 8vw, 8rem);
  border-bottom-right-radius: clamp(64px, 8.533vw, 128px);
  width: 95%;
  padding-left: 5%;
}
@media (min-width: 768px) {
  .company-message__inner {
    display: flex;
    justify-content: flex-end;
    width: 92%;
    padding-left: 8%;
    padding-bottom: clamp(3rem, 11vw, 11rem);
  }
}
@media (min-width: 1597px) {
  .company-message__inner {
    min-height: 600px;
    width: calc(50% + 590px);
  }
}
@media (max-width: 767px) {
  .company-message__inner {
    padding-right: 5%;
  }
}
.company-message__inner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #C30D23;
  width: 80%;
  height: 1px;
}
@media (min-width: 1597px) {
  .company-message__inner::before {
    width: calc(100% - 480px);
  }
}
.company-message__inner__inner {
  width: 100%;
  max-width: 1180px;
  position: relative;
}
@media (max-width: 767px) {
  .company-message__inner__inner {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 1em;
  }
}

@media (min-width: 768px) {
  .company-message__txt {
    width: 57.62%;
    line-height: 2;
  }
}
@media (min-width: 1597px) {
  .company-message__txt {
    font-size: 1.06rem;
  }
}

.company-message__tit {
  font-weight: 700;
  font-size: calc(1.5rem + 12 * (100vw - 376px) / 1124);
  margin-bottom: calc(1rem + 0.75em);
}
@media (min-width: 768px) {
  .company-message__tit {
    font-size: calc(1.5rem + 12 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .company-message__tit {
    font-size: 2.25rem;
  }
}

.company-message__name {
  margin-top: clamp(2rem, 3vw, 3rem);
  font-weight: 700;
  font-size: calc(1rem + 8 * (100vw - 376px) / 1124);
}
@media (min-width: 768px) {
  .company-message__name {
    font-size: calc(1rem + 8 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .company-message__name {
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) {
  .company-message__img {
    position: absolute;
    top: 4vw;
    right: -5%;
    width: 33.89%;
  }
}
@media (min-width: 1597px) {
  .company-message__img {
    top: 5rem;
    width: 400px;
  }
}
@media (max-width: 767px) {
  .company-message__img {
    width: clamp(140px, 30%, 240px);
    margin-left: auto;
    margin-right: auto;
  }
}
.company-message__img__inner {
  border-radius: clamp(8px, 1.06vw, 16px);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

/* company-philosophy */
.company-philosophy {
  position: relative;
  padding-bottom: clamp(4rem, 10vw, 10rem);
  padding-top: clamp(6rem, 40vw, 24rem);
  background: #292D2E;
}
@media (min-width: 1024px) {
  .company-philosophy {
    padding-top: clamp(8rem, 36vw, 20rem);
  }
}
.company-philosophy__bg {
  position: absolute;
  width: 100%;
  height: 75%;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
}
@media (min-width: 1024px) {
  .company-philosophy__bg {
    height: 58.33%;
  }
}
.company-philosophy__bg__inner {
  position: relative;
  position: relative;
  width: 100%;
  z-index: 1;
}
.company-philosophy__bg__inner::before {
  content: "";
  display: block;
  padding-top: 75%;
  position: relative;
}
.company-philosophy__bg__inner > div,
.company-philosophy__bg__inner figure,
.company-philosophy__bg__inner a,
.company-philosophy__bg__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.company-philosophy__bg__inner:after {
  content: "";
  background: rgba(255, 255, 255, 0.36);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@media (min-width: 1024px) {
  .company-philosophy__bg__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .company-philosophy__bg__inner::before {
    content: "";
    display: block;
    padding-top: 58.33%;
    position: relative;
  }
  .company-philosophy__bg__inner > div,
  .company-philosophy__bg__inner figure,
  .company-philosophy__bg__inner a,
  .company-philosophy__bg__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.company-philosophy__cont {
  background: #FFFFFF;
  position: relative;
  z-index: 1;
  border-radius: clamp(16px, 4.26vw, 64px);
  padding: 2em 5% 2.5em;
}
@media (min-width: 768px) {
  .company-philosophy__cont {
    padding: 4em 5% 5em;
  }
}
@media (min-width: 1024px) {
  .company-philosophy__cont {
    padding: 4em 10% 6em;
  }
}
.company-philosophy__add {
  position: absolute;
  line-height: 1;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #C30D23;
  left: 0;
  width: 100%;
  bottom: calc(100% - 0.5em);
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.02em;
  font-size: calc(1.5rem + 48 * (100vw - 376px) / 1124);
}
@media (min-width: 1597px) {
  .company-philosophy__add {
    font-size: 4.5rem;
  }
}
.company-philosophy__add span {
  display: block;
}
.company-philosophy__tit {
  text-align: center;
  font-size: calc(1.75rem + 16 * (100vw - 376px) / 1124);
  letter-spacing: 0.2em;
  margin-bottom: calc(1rem + 0.5em);
}
@media (min-width: 1597px) {
  .company-philosophy__tit {
    font-size: 2.75rem;
  }
}
@media (min-width: 768px) {
  .company-philosophy__txt {
    line-height: 2;
  }
}

.company-philosophy__box:not(:last-child) {
  margin-bottom: clamp(2rem, 4vw, 4rem);
}
.company-philosophy__box__tit {
  font-size: calc(1.14rem + 4 * (100vw - 376px) / 1124);
  line-height: 1.4;
  margin-bottom: calc(0.5rem + 0.3em);
  font-weight: 700;
}
@media (min-width: 768px) {
  .company-philosophy__box__tit {
    text-align: center;
  }
}
@media (min-width: 1597px) {
  .company-philosophy__box__tit {
    font-size: 1.5rem;
  }
}
.company-philosophy__box__tit b {
  color: #C30D23;
}

.company-ozawalogo {
  padding: clamp(4rem, 9vw, 10rem) 0;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.company-ozawalogo__inner {
  text-align: right;
  width: clamp(320px, 75%, 1200px);
  padding-right: 2%;
}

.company-overview {
  position: relative;
  padding-bottom: clamp(6rem, 12vw, 12rem);
}
.company-overview__inner {
  display: flex;
}
@media (min-width: 1024px) {
  .company-overview__inner {
    column-gap: 5%;
    justify-content: space-between;
  }
}
@media (max-width: 1023px) {
  .company-overview__inner {
    flex-direction: column;
  }
}
@media (min-width: 1024px) {
  .company-overview__tit {
    flex-basis: auto;
    flex-shrink: 0;
  }
}
@media (min-width: 1024px) {
  .company-overview__txt {
    flex-basis: 65.33%;
    flex-shrink: 1;
  }
}

.company-accessmap {
  position: relative;
  background-color: #FFFFFF;
}
.company-accessmap__list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(5rem, 8vw, 8rem);
}
@media (min-width: 768px) {
  .company-accessmap__box__inner {
    display: grid;
    grid-template-columns: 34.66% 50%;
    grid-template-rows: auto 1fr;
    column-gap: 15.34%;
  }
}
.company-accessmap__box__tit {
  padding-bottom: 0.75em;
  margin-bottom: 0.75em;
  border-bottom: 1px solid #C30D23;
  font-size: calc(1.25rem + 10 * (100vw - 376px) / 1124);
}
@media (min-width: 768px) {
  .company-accessmap__box__tit {
    padding-top: 1em;
    font-size: calc(1.25rem + 8 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .company-accessmap__box__tit {
    font-size: 1.75rem;
  }
}
.company-accessmap__box__map {
  position: relative;
  padding: 0;
  height: 0;
  overflow: hidden;
  background-color: #FFFFFF;
  z-index: 1;
  border-radius: clamp(8px, 1.06vw, 16px);
}
@media (min-width: 768px) {
  .company-accessmap__box__map {
    padding-bottom: 60%;
    grid-column: 2/3;
    grid-row: 1/3;
  }
}
@media (max-width: 767px) {
  .company-accessmap__box__map {
    padding-bottom: 360px;
    margin-bottom: 0.75em;
  }
}
.company-accessmap__box__map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
@media (max-width: 767px) {
  .company-accessmap__box .btn-wrap {
    margin-left: auto;
    margin-right: auto;
  }
}

/*--------------------------------------------------------------------/
	contact_form
/--------------------------------------------------------------------*/
.contact-intro {
  margin-bottom: clamp(3rem, 6vw, 6rem);
}

.contact-box {
  padding: 2em 5% 2.5em;
  margin-bottom: clamp(3rem, 8vw, 8rem);
  border-radius: clamp(16px, 4.26vw, 64px);
  border: 1px solid #B6C3CF;
  background: #FFFFFF;
}
@media (min-width: 1024px) {
  .contact-box {
    padding: 3.5em 9% 4em;
  }
}

.contact-box__tel {
  display: flex;
  width: 100%;
  margin-bottom: 1em;
}
@media (min-width: 768px) {
  .contact-box__tel {
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .contact-box__tel {
    flex-direction: column;
    align-items: center;
    row-gap: 0.25em;
  }
}
.contact-box__tel__tit {
  color: #202020;
  overflow: hidden;
  font-size: calc(1.14rem + 6 * (100vw - 376px) / 1124);
}
@media (min-width: 768px) {
  .contact-box__tel__tit {
    font-size: calc(1.5rem + 4 * (100vw - 768px) / 732);
    width: 52%;
    column-gap: 4%;
  }
}
@media (min-width: 1597px) {
  .contact-box__tel__tit {
    font-size: 1.5rem;
  }
}
.contact-box__tel__tit span {
  position: relative;
}
@media (min-width: 768px) {
  .contact-box__tel__tit span {
    display: inline-block;
    padding-right: 0.5em;
  }
  .contact-box__tel__tit span::after {
    content: "";
    width: 100%;
    height: 1px;
    background: #C30D23;
    position: absolute;
    top: 50%;
    left: 100%;
  }
}
.contact-box__tel__num {
  font-family: 'Roboto', sans-serif;
  font-size: calc(1.75rem + 20 * (100vw - 376px) / 1124);
  letter-spacing: 0;
  position: relative;
}
.contact-box__tel__num:before {
  content: "\e909";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (min-width: 768px) {
  .contact-box__tel__num {
    font-size: calc(2rem + 14 * (100vw - 768px) / 732);
    width: 44%;
  }
}
@media (min-width: 1597px) {
  .contact-box__tel__num {
    font-size: 2.75rem;
  }
}
.contact-box__tel__num::before {
  font-size: 0.71em;
  color: #C30D23;
  padding-right: 0.2em;
}
@media (min-width: 576px) {
  .contact-box__tel__num::before {
    font-size: 0.66em;
  }
}
@media (min-width: 1024px) {
  .contact-box__tel__num::before {
    font-size: 0.62em;
  }
}
.contact-box__tel__num a {
  color: #202020;
}

.contact-box__line {
  display: flex;
  width: 100%;
  background: #ECFFEC;
  border-radius: clamp(8px, 1.06vw, 16px);
  padding: 1.5em 2em;
}
@media (min-width: 768px) {
  .contact-box__line {
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .contact-box__line {
    flex-direction: column;
    align-items: center;
    row-gap: 0.75em;
  }
}
.contact-box__line__tit {
  color: #00B900;
  font-size: calc(1.14rem + 6 * (100vw - 376px) / 1124);
}
@media (min-width: 768px) {
  .contact-box__line__tit {
    font-size: calc(1.14rem + 6 * (100vw - 768px) / 732);
    width: 50%;
    column-gap: 4%;
  }
}
@media (min-width: 1597px) {
  .contact-box__line__tit {
    font-size: 1.38rem;
  }
}
.contact-box__line__tit span {
  position: relative;
  display: inline-block;
  padding-right: 0.5em;
}
.contact-box__line__tit span::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #C30D23;
  position: absolute;
  top: 50%;
  left: 100%;
}
@media (min-width: 768px) {
  .contact-box__line__btn {
    width: 42%;
  }
}
@media (max-width: 767px) {
  .contact-box__line__btn {
    width: 100%;
  }
}
.contact-box__line__btn a {
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-size: calc(1rem + 8 * (100vw - 376px) / 1124);
  letter-spacing: 0;
  position: relative;
  background: #00B900;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5em;
  padding: 0.5em 5%;
  border-radius: 999px;
  transition: all 0.3s ease 0s;
  width: 100%;
  text-decoration: none !important;
}
@media (min-width: 768px) {
  .contact-box__line__btn a {
    font-size: calc(1.14rem + 6 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .contact-box__line__btn a {
    font-size: 1.5rem;
  }
}
.contact-box__line__btn a:hover {
  background: #00ec00;
}

.contact-privacy {
  border: 1px solid #B6C3CF;
  border-radius: clamp(8px, 1.06vw, 16px);
  background: #FFFFFF;
}
@media (min-width: 1024px) {
  .contact-privacy {
    padding: 3rem 6% 0;
  }
}
@media (min-width: 1024px) {
  .contact-privacy__inner {
    margin-top: 1.5rem;
    padding-bottom: 3rem;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #B6C3CF #FFFFFF;
    max-height: 300px;
  }
  .contact-privacy__inner::-webkit-scrollbar {
    width: 8px;
  }
  .contact-privacy__inner::-webkit-scrollbar-track {
    background: transparent;
  }
  .contact-privacy__inner::-webkit-scrollbar-thumb {
    background: #B6C3CF;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px #FFFFFF;
  }
}
.contact-privacy__tit {
  font-size: calc(1.14rem + 4 * (100vw - 376px) / 1124);
  font-weight: 700;
  text-align: center;
  position: relative;
}
@media (min-width: 1024px) {
  .contact-privacy__tit {
    margin-bottom: 3rem;
    padding-bottom: 1rem;
  }
}
@media (min-width: 1597px) {
  .contact-privacy__tit {
    font-size: 1.38rem;
  }
}
@media (max-width: 1023px) {
  .contact-privacy__tit {
    padding: 1.5rem 6%;
    cursor: pointer;
    position: relative;
  }
  .contact-privacy__tit:after {
    content: "\e313";
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .contact-privacy__tit::after {
    position: absolute;
    right: 1rem;
    color: #979DA4;
    top: calc(50% - 10px);
    transition: all 0.3s ease 0s;
    font-size: 1.5rem;
  }
}
@media (min-width: 1024px) {
  .contact-privacy__tit::before {
    content: "";
    position: absolute;
    left: calc(50% - 3rem);
    bottom: 0;
    width: 6rem;
    height: 4px;
    border-radius: 2px;
    background: #C30D23;
  }
}
.contact-privacy__tit.active::after {
  transform: rotate(180deg);
}
.contact-privacy__txt {
  padding: 0 2rem 2rem;
}
@media (min-width: 1024px) {
  .contact-privacy__txt {
    padding-bottom: 3rem;
  }
}
.contact-privacy .txt {
  font-size: 1.07em;
}
@media (min-width: 576px) {
  .contact-privacy .txt {
    font-size: 1em;
  }
}
@media (min-width: 1024px) {
  .contact-privacy .txt {
    font-size: 0.93em;
  }
}

.contact-form__tit {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: #C30D23;
  font-weight: 700;
  margin-bottom: calc(1rem + 0.5em);
  font-size: calc(1.5rem + 16 * (100vw - 376px) / 1124);
}
@media (min-width: 1597px) {
  .contact-form__tit {
    font-size: 2.5rem;
  }
}

.contact-table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  line-height: 1.2;
  position: relative;
}
.contact-table th, .contact-table td {
  text-align: left;
}
@media (min-width: 1024px) {
  .contact-table th, .contact-table td {
    display: table-cell;
    padding: 1rem 0 1.5rem;
  }
}
@media (max-width: 1023px) {
  .contact-table th, .contact-table td {
    display: block;
    padding: 0;
  }
}
.contact-table th {
  vertical-align: top;
  font-weight: 500;
}
@media (min-width: 1024px) {
  .contact-table th {
    width: 30%;
  }
}
.contact-table th .required {
  margin-left: 0.5rem;
}
@media (min-width: 1024px) {
  .contact-table td {
    width: auto;
  }
}
@media (max-width: 1023px) {
  .contact-table td {
    padding: 1rem 0 2rem;
  }
}
.contact-table textarea {
  width: 100%;
}

input,
select {
  width: auto;
  font-family: 'Noto Sans JP', sans-serif;
}
input.long,
select.long {
  width: 100%;
}
input.middle,
select.middle {
  width: 100%;
}
@media (min-width: 768px) {
  input.middle,
  select.middle {
    width: 70%;
  }
}
input.short,
select.short {
  width: 70%;
}
@media (min-width: 768px) {
  input.short,
  select.short {
    width: 30%;
  }
}
input.mini,
select.mini {
  width: 6em;
}
@media (min-width: 576px) {
  input.mini,
  select.mini {
    width: 8em;
  }
}

button[class*=sub-btn],
button[class*=back-btn],
button[class*=chack-btn] {
  font-size: 1.13rem;
  letter-spacing: 0.1em;
  font-family: 'Noto Sans JP', sans-serif;
  text-align: left;
  outline: none;
}

button[class*=sub-btn] {
  font-weight: 500;
  display: block;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #FFFFFF;
  background-color: #C30D23;
  border: 1px solid;
  border-color: #C30D23;
  padding: 1.5em 5em 1.5em 1.75em;
  width: 100%;
  border-radius: 999px;
}
button[class*=sub-btn] span::before {
  content: "";
  width: 8px;
  height: 8px;
  background: #FFFFFF;
  position: absolute;
  top: 50%;
  border-radius: 50%;
  right: 1.75em;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}
button[class*=sub-btn] span::after {
  content: "";
  width: 32px;
  height: 1px;
  background: #FFFFFF;
  position: absolute;
  top: 50%;
  left: calc(100% - 1.75em - 40px);
  transition: all 0.3s ease 0s;
}
button[class*=sub-btn]:hover {
  background-color: #FFFFFF;
  background-color: #e3021d;
  border-color: #e3021d;
}
button[class*=sub-btn]:hover span::before {
  right: calc(1.75em - 5px);
}
button[class*=sub-btn]:hover span::after {
  width: 37px;
}

button[class*=back-btn] {
  margin-top: 1rem;
  font-weight: 500;
  display: block;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #FFFFFF;
  background-color: #4B4D4E;
  border: 1px solid;
  border-color: #4B4D4E;
  padding: 1.5em 5em 1.5em 1.75em;
  width: 100%;
  border-radius: 999px;
}
button[class*=back-btn] span::before {
  content: "";
  width: 8px;
  height: 8px;
  background: #FFFFFF;
  position: absolute;
  top: 50%;
  border-radius: 50%;
  right: 1.75em;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}
button[class*=back-btn] span::after {
  content: "";
  width: 32px;
  height: 1px;
  background: #FFFFFF;
  position: absolute;
  top: 50%;
  left: calc(100% - 1.75em - 40px);
  transition: all 0.3s ease 0s;
}
button[class*=back-btn]:hover {
  background-color: #FFFFFF;
  background-color: #e3021d;
  border-color: #e3021d;
}
button[class*=back-btn]:hover span::before {
  right: calc(1.75em - 5px);
}
button[class*=back-btn]:hover span::after {
  width: 37px;
}

button[class*=chack-btn] {
  font-weight: 500;
  display: block;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #FFFFFF;
  background-color: #C30D23;
  border: 1px solid;
  border-color: #C30D23;
  padding: 1.5em 5em 1.5em 1.75em;
  width: 100%;
  border-radius: 999px;
}
button[class*=chack-btn] span::before {
  content: "";
  width: 8px;
  height: 8px;
  background: #FFFFFF;
  position: absolute;
  top: 50%;
  border-radius: 50%;
  right: 1.75em;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}
button[class*=chack-btn] span::after {
  content: "";
  width: 32px;
  height: 1px;
  background: #FFFFFF;
  position: absolute;
  top: 50%;
  left: calc(100% - 1.75em - 40px);
  transition: all 0.3s ease 0s;
}
button[class*=chack-btn]:hover {
  background-color: #FFFFFF;
  background-color: #e3021d;
  border-color: #e3021d;
}
button[class*=chack-btn]:hover span::before {
  right: calc(1.75em - 5px);
}
button[class*=chack-btn]:hover span::after {
  width: 37px;
}

/* form-parts
----------------------------------------------------------------*/
select, textarea, input {
  background-color: #FCFCFC;
  padding: 1em 1.5em;
  border: 1px solid #B6C3CF;
  border-radius: 4px;
  line-height: 1.5;
}
select:focus, textarea:focus, input:focus {
  background-color: #FFFFFF;
  box-shadow: rgba(58, 134, 232, 0.25) 0 0 4px 1px;
}

input:-ms-input-placeholder, input:-moz-placeholder, input::-webkit-input-placeholder {
  color: #979DA4;
}

textarea {
  height: 10em;
}

/* radio-box,check-box
----------------------------------------------------------------*/
/* variablea */
/* radio-btn */
.mwform-radio-field {
  cursor: pointer;
}
.mwform-radio-field:not(:last-child) {
  margin-right: 1em;
}

.mwform-radio-field-text {
  vertical-align: top;
  position: relative;
  padding-left: 34px;
  display: block;
}

input[type=radio] {
  position: absolute;
  opacity: 0;
}
input[type=radio] + .mwform-radio-field-text:before {
  content: "";
  background: #FCFCFC;
  border-radius: 100%;
  border: 1px solid #979DA4;
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: calc(50% - (20px/ 2));
  left: 0;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 0.4s ease;
}
input[type=radio]:checked + .mwform-radio-field-text:before {
  background-color: #C30D23;
  box-shadow: inset 0 0 0 5px #E2E6E9;
}
input[type=radio]:focus + .mwform-radio-field-text:before {
  outline: none;
  border-color: #C30D23;
}
input[type=radio]:disabled + .mwform-radio-field-text:before {
  box-shadow: inset 0 0 0 4px #979DA4;
  border-color: #979DA4;
  background: #FCFCFC;
}
input[type=radio] + .mwform-radio-field-text:empty:before {
  margin-right: 0;
}

.vertical-item:not(:first-of-type) {
  margin-top: 1rem !important;
}

/* chack-btn */
@-moz-keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}
@-webkit-keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}
@keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}
@keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}
@-webkit-keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}
@-moz-keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}
input[type=checkbox] {
  display: none;
}

.check-box .vertical-item:not(:first-of-type) {
  margin-top: 1.5rem !important;
}
.check-box label {
  position: relative;
  cursor: pointer;
}
.check-box label::before {
  content: "";
  height: 26px;
  width: 26px;
  background-color: transparent;
  border: 1px solid #979DA4;
  margin-right: 1em;
  position: absolute;
  top: -0.15em;
  left: 0;
  display: block;
  transition: border-color ease 0.2s;
  z-index: 4;
}

.mwform-checkbox-field-text {
  vertical-align: top;
  padding-left: calc(26px + 1rem);
  display: block;
}
.mwform-checkbox-field-text::before, .mwform-checkbox-field-text::after {
  position: absolute;
  height: 0;
  width: 5.2px;
  background-color: #C30D23;
  display: inline-block;
  transform-origin: left top;
  content: "";
  transition: opacity ease 0.5;
  z-index: 3;
}
.mwform-checkbox-field-text::before {
  top: 22.36px;
  left: 10.4px;
  transform: rotate(-135deg);
}
.mwform-checkbox-field-text::after {
  top: 13px;
  left: 0.78px;
  transform: rotate(-45deg);
}

input[type=checkbox]:checked + .mwform-checkbox-field-text,
.mwform-checkbox-field-text.checked {
  border-color: #C30D23;
}
input[type=checkbox]:checked + .mwform-checkbox-field-text::after,
.mwform-checkbox-field-text.checked::after {
  height: 13px;
  animation: dothabottomcheck 0.2s ease 0s forwards;
}
input[type=checkbox]:checked + .mwform-checkbox-field-text::before,
.mwform-checkbox-field-text.checked::before {
  height: 31.2px;
  animation: dothatopcheck 0.4s ease 0s forwards;
  box-shadow: 0 0 0 1.3px #FFFFFF;
}

.mw_wp_form .error {
  margin-top: 0.7rem;
}

/*--------------------------------------------------------------------/
	blog
/--------------------------------------------------------------------*/
.contents-flex {
  display: flex;
}
@media (min-width: 1024px) {
  .contents-flex {
    justify-content: space-between;
  }
}
@media (max-width: 1023px) {
  .contents-flex {
    flex-direction: column;
    row-gap: clamp(4rem, 9vw, 9rem);
  }
}

.contents-left {
  width: 100%;
}
@media (min-width: 1024px) {
  .contents-left {
    width: calc(96% - 300px);
  }
}
@media (min-width: 1597px) {
  .contents-left {
    width: 72%;
  }
}

/*--------------------------------------------------------------------/
	single-page
/--------------------------------------------------------------------*/
.single-main {
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.single-main__img {
  border-radius: clamp(8px, 1.06vw, 16px);
  overflow: hidden;
  z-index: 1;
}
.single-main figcaption {
  margin-top: 0.75em;
  margin-left: 0.2em;
  line-height: 1.5;
  font-size: 0.9em;
  padding-left: 1.5em;
  position: relative;
}
.single-main figcaption::before {
  content: "●";
  font-size: 0.8em;
  position: absolute;
  top: 0.2em;
  left: 0;
  color: #C30D23;
}

.single-cont__txt {
  margin-bottom: 4rem;
}
.single-cont__txt::after {
  display: block;
  content: "";
  clear: both;
}
@media (min-width: 768px) {
  .single-cont__txt {
    margin-bottom: 6rem;
  }
}
.single-cont .title {
  border-top: 1px solid #C30D23;
  padding-top: 1em;
  padding-bottom: 1em;
  font-weight: 700;
  font-size: calc(1.14rem + 8 * (100vw - 376px) / 1124);
}
@media (min-width: 1024px) {
  .single-cont .title {
    font-size: calc(1.25rem + 6 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1597px) {
  .single-cont .title {
    font-size: 1.63rem;
  }
}

.single-cont .txt p {
  margin-bottom: clamp(1rem, 3vw, 3rem);
}
.single-cont__foot {
  border-top: 1px solid #B6C3CF;
  padding-bottom: 1em;
}
.single-cont__addtoany {
  padding-top: 1.5em;
}
.single-cont__tag {
  padding-top: 1em;
}
.single-cont__tag a {
  font-size: 1rem !important;
  color: #202020;
  padding-left: 1.5em;
  margin-right: 0.5rem;
  position: relative;
  text-decoration: none !important;
}
.single-cont__tag a:before {
  content: "\e901";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.single-cont__tag a::before {
  position: absolute;
  top: 0.6em;
  left: 0.2rem;
  font-size: 0.85em;
  color: #C30D23;
  text-decoration: none !important;
}
.single-cont__tag a:hover {
  color: #C30D23;
  text-decoration: underline !important;
}

.topics-interview {
  padding-bottom: clamp(3rem, 5vw, 5rem);
}
.topics-interview__flex {
  display: flex;
}
@media (min-width: 768px) {
  .topics-interview__flex {
    justify-content: space-between;
  }
  .topics-interview__flex.is-right {
    flex-direction: row-reverse;
  }
}
@media (max-width: 767px) {
  .topics-interview__flex {
    flex-direction: column;
    row-gap: 0.75rem;
  }
}
@media (min-width: 768px) {
  .topics-interview__flex .topics-interview__img {
    width: 38%;
  }
}
.topics-interview__flex .topics-interview__img__inner {
  border-radius: 8px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .topics-interview__flex .topics-interview__txt {
    width: 58%;
  }
}

.topics-video {
  padding: clamp(1.5em, 2vw, 3em) 5%;
  margin-bottom: clamp(3rem, 5vw, 5rem);
  background: #FDF7F7;
  border-radius: clamp(8px, 1.06vw, 16px);
}
@media (min-width: 768px) {
  .topics-video__inner {
    display: grid;
    grid-template-columns: 50% 46.5%;
    grid-template-rows: auto 1fr;
    column-gap: 4.5%;
  }
}
.topics-video__tit {
  margin-bottom: calc(1rem + 0.3em);
  font-size: calc(1rem + 8 * (100vw - 376px) / 1124);
  font-weight: 700;
  position: relative;
}
@media (min-width: 768px) {
  .topics-video__tit {
    font-size: calc(1.07rem + 5 * (100vw - 768px) / 732);
    grid-column: 1/2;
    grid-row: 1/2;
  }
}
@media (min-width: 1597px) {
  .topics-video__tit {
    font-size: 1.38rem;
  }
}
@media (min-width: 768px) {
  .topics-video__video {
    grid-column: 2/3;
    grid-row: 1/3;
  }
}
@media (max-width: 767px) {
  .topics-video__video {
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    margin-bottom: 1rem;
  }
}
.topics-video__video__inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  border-radius: 8px;
  overflow: hidden;
  z-index: 4;
}
.topics-video__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .topics-video__txt {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}

/* topics-table */
.topics-table {
  padding-bottom: clamp(3rem, 5vw, 5rem);
}
.topics-table .title {
  padding: 1em 2%;
  text-align: center;
  border-top: 1px solid #C30D23;
  font-size: calc(1rem + 8 * (100vw - 376px) / 1124);
  font-weight: 600;
  margin-bottom: calc(1rem + 0.3em);
}
@media (min-width: 1597px) {
  .topics-table .title {
    font-size: 1.5rem;
  }
}

.wp-block-gallery {
  margin-bottom: 2em;
}

.wp-block-image {
  overflow: hidden;
  border-radius: clamp(8px, 1.06vw, 16px);
}

.wp-block-file {
  margin-top: 3em;
  margin-bottom: 3em;
}
.wp-block-file a {
  padding-left: 1.5em;
  padding-right: 1em;
  color: #202020;
  font-weight: 700;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
  background: #FFFFFF;
  position: relative;
}
@media (min-width: 768px) {
  .wp-block-file a {
    font-size: 1.14em;
  }
}
.wp-block-file a::before {
  content: "●";
  color: #C30D23;
  position: absolute;
  top: 0.15em;
  left: 0;
  font-size: 0.8em;
}
.wp-block-file__button {
  padding: 0.75em 1.25em;
  border: 1px solid #C30D23;
  color: #FFFFFF !important;
  font-weight: 700;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
  border-radius: 999px;
  background: #C30D23 !important;
}
@media (min-width: 768px) {
  .wp-block-file__button {
    font-size: 0.8rem !important;
  }
}
.wp-block-file__button::before {
  display: none;
}
.wp-block-file__button:hover {
  color: #C30D23 !important;
  background: #FFFFFF !important;
}

.wp-block-group {
  margin-bottom: clamp(3rem, 6vw, 6rem) !important;
}

.wp-block-columns {
  margin-bottom: clamp(3rem, 6vw, 6rem) !important;
}
.wp-block-columns h2 {
  margin-bottom: 1em !important;
}

/*--------------------------------------------------------------------/
	aside
/--------------------------------------------------------------------*/
@media (min-width: 1024px) {
  .aside {
    width: 300px;
  }
}

.widget-blog-aside:not(:last-child) {
  margin-bottom: 2.5rem;
}
.widget-blog-aside__tit {
  padding: 1em 1.5em;
  margin-bottom: 1rem;
  font-weight: 500;
  line-height: 1;
  color: #FFFFFF;
  background: #C30D23;
  border-radius: 999px;
}
@media (min-width: 1024px) {
  .widget-blog-aside__tit {
    font-size: 1.28em;
  }
}
@media (min-width: 1024px) and (min-width: 576px) {
  .widget-blog-aside__tit {
    font-size: 1.2em;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) {
  .widget-blog-aside__tit {
    font-size: 1.12em;
  }
}
.widget-blog-aside ul li:not(:last-of-type) {
  margin-bottom: 1em;
}
.widget-blog-aside ul li a {
  padding-left: 1.5em;
  color: #202020;
  text-decoration: none !important;
  position: relative;
  display: block;
  line-height: 1.5;
  transition: all 0.3s ease 0s;
}
.widget-blog-aside ul li a::before {
  position: absolute;
  top: 0.2em;
  left: 0;
  content: "●";
  display: block;
  font-size: 0.8em;
  color: #C30D23;
  text-decoration: none !important;
}
.widget-blog-aside ul li a:hover {
  color: #e3021d;
}

.wp-widget-group__inner-blocks {
  padding: 0 5% !important;
}

.tag-cloud-link {
  font-size: 1rem !important;
  color: #202020;
  padding-left: 1.5rem;
  margin-right: 0.5rem;
  position: relative;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.tag-cloud-link:before {
  content: "\e901";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tag-cloud-link::before {
  position: absolute;
  top: 0.75em;
  left: 0.2em;
  font-size: 0.8em;
  color: #C30D23;
  text-decoration: none !important;
}
.tag-cloud-link:hover {
  color: #e3021d;
}

/* calendar
------------------------------------*/
#wp-calendar {
  border-collapse: separate;
  width: 100%;
}

.calendar_wrap {
  padding: 0.5rem 0.75rem;
  border: 1px solid #B6C3CF;
}

.wp-calendar-table {
  margin-bottom: 0.75rem;
}
.wp-calendar-table caption {
  padding: 0.3rem 2%;
}
.wp-calendar-table thead th {
  text-align: center;
  padding: 6px;
  width: auto;
  color: #979DA4;
  font-weight: 500;
}
.wp-calendar-table td {
  text-align: center;
  background: #E2E6E9;
  line-height: 2;
  border: 2px solid #FFFFFF;
  font-size: 1.07em;
}
@media (min-width: 576px) {
  .wp-calendar-table td {
    font-size: 1em;
  }
}
@media (min-width: 1024px) {
  .wp-calendar-table td {
    font-size: 0.93em;
  }
}
.wp-calendar-table td a {
  background-color: #C30D23;
  color: #FFFFFF;
  transition: all 0.3s ease 0s;
  display: block;
  text-decoration: none !important;
}
.wp-calendar-table td a:hover {
  background-color: #EDEEF6;
}
.wp-calendar-table td.pad {
  background: transparent;
}
.wp-calendar-table td#today {
  background: #979DA4;
  color: #FFFFFF;
}
.wp-calendar-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0.3rem;
}
.wp-calendar-nav a {
  display: block;
  color: #202020;
  padding: 0.4rem 1rem;
  background-color: #E2E6E9;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.wp-calendar-nav a:hover {
  background-color: #979DA4;
  color: #FFFFFF;
}

[name=archive-dropdown] {
  width: 100% !important;
}

/*--------------------------------------------------------------------/
	search-form
/--------------------------------------------------------------------*/
.search-form {
  position: relative;
  width: 100%;
  border: 1px solid #979DA4;
  display: flex;
  justify-content: space-between;
}
.search-form__txt {
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  outline: 0;
  background: #FFFFFF;
  appearance: none;
  border: none;
  width: calc(100% - 50px);
}
.search-form__btn {
  height: 40px;
  width: 50px;
  padding: 2px;
  background: none;
  font-size: 2em;
  vertical-align: bottom;
  color: #B6C3CF;
  background: #FFFFFF;
  transition: all 0.3s ease 0s;
  border: none;
}
.search-form__btn i {
  font-size: 1.25rem;
}
.search-form__btn:hover {
  color: #2b74e0;
}

/*====================================================================

lv2.css

=====================================================================*/
/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
  position: relative;
  padding-top: 64px;
  padding-bottom: clamp(4rem, 6vw, 6rem);
}
@media (min-width: 1024px) {
  .main-view {
    padding-bottom: clamp(6rem, 9vw, 8rem);
  }
}
@media (min-width: 1240px) {
  .main-view {
    padding-top: 80px;
  }
}
.main-view__inner {
  position: relative;
  display: flex;
  width: 100%;
  height: 75vw;
}
@media (min-width: 1024px) {
  .main-view__inner {
    align-items: center;
    height: 54vw;
  }
}
@media (min-width: 1597px) {
  .main-view__inner {
    height: 600px;
  }
}
@media (min-width: 1920px) {
  .main-view__inner {
    height: 31.25vw;
  }
}
@media (max-width: 1023px) {
  .main-view__inner {
    align-items: flex-end;
  }
}
.main-view::before {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  position: absolute;
  background: #C30D23;
  width: 89.0625%;
  height: 60%;
  border-top-right-radius: clamp(64px, 8.533vw, 128px);
  border-bottom-right-radius: clamp(64px, 8.533vw, 128px);
}

.main-view__bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 70vw;
  z-index: 2;
  height: 80%;
  overflow: hidden;
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}
@media (min-width: 1024px) {
  .main-view__bg {
    width: 59.375vw;
    height: 100%;
  }
}
.main-view__bg__inner {
  height: 100%;
}

.main-view__tit {
  background: #FFFFFF;
  position: relative;
  z-index: 3;
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  width: 70vw;
  padding: clamp(2em, 8vw, 8rem) clamp(2em, 9.38vw, 8rem) clamp(2em, 8vw, 8rem) 0;
}
@media (min-width: 1024px) {
  .main-view__tit {
    padding: clamp(4em, 6.4vw, 6rem) clamp(6em, 8.533vw, 8rem) clamp(4em, 6.4vw, 6rem) 0;
    width: 50vw;
  }
}
@media (min-width: 1597px) {
  .main-view__tit {
    padding: 7em 8em 7em 0;
    min-width: 760px;
    display: flex;
    align-items: center;
  }
}
.main-view__tit__inner {
  position: relative;
  padding-left: 5vw;
}
@media (min-width: 1740px) {
  .main-view__tit__inner {
    padding-left: 6.77vw;
  }
}
@media (max-width: 1023px) {
  .main-view__tit {
    margin-bottom: clamp(2em, 4vw, 4em);
  }
}
.main-view__tit__txt.is-404 em {
  font-size: calc(1.5rem + 30 * (100vw - 280px) / 1220);
}
@media (min-width: 1024px) {
  .main-view__tit__txt.is-404 em {
    font-size: calc(3rem + 32 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1597px) {
  .main-view__tit__txt.is-404 em {
    font-size: 4.5rem;
  }
}
.main-view__tit__txt.is-404 span {
  font-size: calc(1rem + 16 * (100vw - 280px) / 1220);
}
@media (min-width: 1597px) {
  .main-view__tit__txt.is-404 span {
    font-size: 2rem;
  }
}
.main-view__tit__txt.is-anothertype em {
  font-size: calc(1.25rem + 48 * (100vw - 280px) / 1220);
  font-weight: 700;
  line-height: 1;
  font-family: 'Roboto', sans-serif;
  display: block;
  font-style: normal;
  color: #C30D23;
  margin-bottom: 0.1em;
}
@media (min-width: 1024px) {
  .main-view__tit__txt.is-anothertype em {
    font-size: calc(3rem + 24 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1597px) {
  .main-view__tit__txt.is-anothertype em {
    font-size: 4.5rem;
  }
}
.main-view__tit__txt.is-term em {
  font-size: calc(1.14rem + 28 * (100vw - 280px) / 1220);
}
@media (min-width: 1024px) {
  .main-view__tit__txt.is-term em {
    font-size: calc(2.5rem + 8 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1597px) {
  .main-view__tit__txt.is-term em {
    font-size: 3rem;
  }
}
.main-view__tit__txt.is-term h1 {
  font-size: calc(1rem + 16 * (100vw - 376px) / 1124);
}
@media (min-width: 1597px) {
  .main-view__tit__txt.is-term h1 {
    font-size: 2rem;
  }
}
.main-view__tit__txt.is-single em {
  font-size: calc(1.14rem + 28 * (100vw - 280px) / 1220);
}
@media (min-width: 1024px) {
  .main-view__tit__txt.is-single em {
    font-size: calc(2.5rem + 8 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1597px) {
  .main-view__tit__txt.is-single em {
    font-size: 3rem;
  }
}
.main-view__tit__txt.is-single h1 {
  font-size: calc(1rem + 16 * (100vw - 280px) / 1220);
}
@media (min-width: 1597px) {
  .main-view__tit__txt.is-single h1 {
    font-size: 2rem;
  }
}
.main-view__tit__txt em {
  font-size: calc(2rem + 64 * (100vw - 376px) / 1124);
  font-weight: 700;
  line-height: 1.2;
  font-family: 'Roboto', sans-serif;
  display: block;
  font-style: normal;
  color: #C30D23;
}
@media (min-width: 1024px) {
  .main-view__tit__txt em {
    font-size: calc(4rem + 32 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1597px) {
  .main-view__tit__txt em {
    font-size: 6rem;
  }
}
.main-view__tit__txt h1 {
  letter-spacing: 0.05em;
  font-weight: 700;
  font-size: calc(1.14rem + 20 * (100vw - 376px) / 1124);
  padding: 0 0 0 0.1em;
  position: relative;
  display: block;
  line-height: 1.2;
}
@media (min-width: 1597px) {
  .main-view__tit__txt h1 {
    font-size: 2.5rem;
  }
}
.main-view__tit__date {
  margin-top: 0.5em;
  letter-spacing: 0.02em;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #979DA4;
  font-size: calc(0.84rem + 4 * (100vw - 376px) / 1124);
}
@media (min-width: 1024px) {
  .main-view__tit__date {
    font-size: calc(1rem + 4 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1597px) {
  .main-view__tit__date {
    font-size: 1.25rem;
  }
}
.main-view__tit .category-list {
  margin-top: 0.5em;
}
@media (min-width: 1024px) {
  .main-view__tit .category-list {
    margin-top: 0.75em;
  }
}
.main-view__tit .category-list .category-item {
  line-height: 1.2;
  padding: 0.5em 1.75em;
  vertical-align: top;
  font-size: calc(0.75rem + 2 * (100vw - 376px) / 1124);
}
@media (min-width: 1024px) {
  .main-view__tit .category-list .category-item {
    font-size: calc(1rem + 4 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1597px) {
  .main-view__tit .category-list .category-item {
    font-size: 1.25rem;
  }
}

.contents {
  padding-top: clamp(4rem, 10vw, 10rem);
}

/*--------------------------------------------------------------------/
	共通パーツ
/--------------------------------------------------------------------*/
.common-intro {
  position: relative;
  margin-bottom: clamp(6rem, 12vw, 12rem);
}
@media (min-width: 1024px) {
  .common-intro {
    padding-bottom: clamp(3rem, 7vw, 7rem);
  }
}
@media (max-width: 1023px) {
  .common-intro {
    padding-bottom: clamp(5rem, 16vw, 16rem);
  }
}
.common-intro::before {
  position: absolute;
  bottom: 0;
  right: 0;
  content: "";
  width: 95%;
  height: 70%;
  background: #C30D23;
  border-top-left-radius: clamp(64px, 8.533vw, 128px);
  border-bottom-left-radius: clamp(64px, 8.533vw, 128px);
}
@media (min-width: 1024px) {
  .common-intro::before {
    height: 80%;
    width: 80.73%;
  }
}
.common-intro__inner {
  position: relative;
}

.common-intro__txt {
  position: relative;
  z-index: 2;
  background: #FFFFFF;
  position: relative;
  padding-left: 5vw;
  padding-right: 5vw;
  display: flex;
  justify-content: flex-end;
  border-bottom-right-radius: clamp(64px, 8.533vw, 128px);
}
@media (min-width: 1024px) {
  .common-intro__txt {
    width: 68.125%;
    padding-right: clamp(2em, 12.38vw, 12rem);
    padding-bottom: clamp(2em, 8vw, 8rem);
  }
}
@media (min-width: 1597px) {
  .common-intro__txt {
    min-height: 660px;
  }
}
@media (min-width: 1740px) {
  .common-intro__txt {
    padding-left: 6.77vw;
  }
}
@media (max-width: 1023px) {
  .common-intro__txt {
    width: 95%;
    max-width: 800px;
    padding-bottom: clamp(2em, 48.96vw, 460px);
  }
}
@media (max-width: 575px) {
  .common-intro__txt {
    padding-bottom: clamp(2em, 54.96vw, 460px);
  }
}
.common-intro__txt__inner {
  position: relative;
  padding-top: clamp(2rem, 3vw, 3rem);
  width: 100%;
}
.common-intro__txt__inner::before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 100vw;
  height: 1px;
  background: #C30D23;
}
.common-intro__txt__tit {
  font-weight: 900;
  font-size: calc(1rem + 20 * (100vw - 280px) / 1220);
  margin-bottom: calc(1rem + 0.7em);
}
@media (min-width: 1024px) {
  .common-intro__txt__tit {
    font-size: calc(1.5rem + 6 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1597px) {
  .common-intro__txt__tit {
    font-size: 2rem;
  }
}
@media (min-width: 1740px) {
  .common-intro__txt__tit {
    font-size: 2.38rem;
  }
}
.common-intro__txt__tit b {
  color: #C30D23;
}
.common-intro__txt__tit strong {
  font-size: 2em;
}
@media (min-width: 1024px) {
  .common-intro__txt__txt {
    font-size: 1.06rem;
  }
}
@media (min-width: 1597px) {
  .common-intro__txt__txt {
    max-width: 760px;
  }
}
.common-intro__txt__txt ul {
  text-align: left;
}
.common-intro__txt__txt ul li {
  line-height: 1.4;
  padding-left: 1.5rem;
  position: relative;
}
.common-intro__txt__txt ul li::before {
  content: "●";
  color: #C30D23;
  position: absolute;
  left: 0;
  top: 0.2em;
  font-size: 0.8em;
}
.common-intro__txt__txt ul li:not(:last-child) {
  margin-bottom: 0.75em;
}

.common-intro__img {
  position: absolute;
  z-index: 3;
  right: 0;
  width: 80%;
}
@media (min-width: 1024px) {
  .common-intro__img {
    top: clamp(2em, 4vw, 4rem);
    width: 38.54%;
  }
}
@media (min-width: 1024px) and (max-width: 1596px) {
  .common-intro__img {
    height: 100%;
  }
}
@media (min-width: 1597px) {
  .common-intro__img {
    height: auto;
  }
}
@media (max-width: 1023px) {
  .common-intro__img {
    bottom: -8vw;
  }
}
.common-intro__img__inner {
  margin: 0;
  border-top-left-radius: clamp(8px, 1.06vw, 16px);
  border-bottom-left-radius: clamp(8px, 1.06vw, 16px);
  overflow: hidden;
}
@media (min-width: 1024px) and (max-width: 1596px) {
  .common-intro__img__inner {
    height: 100%;
  }
  .common-intro__img__inner > div {
    height: 100%;
  }
}
@media (min-width: 1597px) {
  .common-intro__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .common-intro__img__inner::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .common-intro__img__inner > div,
  .common-intro__img__inner figure,
  .common-intro__img__inner a,
  .common-intro__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .common-intro__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .common-intro__img__inner::before {
    content: "";
    display: block;
    padding-top: 56.25%;
    position: relative;
  }
  .common-intro__img__inner > div,
  .common-intro__img__inner figure,
  .common-intro__img__inner a,
  .common-intro__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 575px) {
  .common-intro__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .common-intro__img__inner::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .common-intro__img__inner > div,
  .common-intro__img__inner figure,
  .common-intro__img__inner a,
  .common-intro__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}